import './Nav.css'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthService from '../login/AuthService'
import ReactTooltip from 'react-tooltip'
import NomeUsuario from './NomeUsuario'
import { getHachimonTokenApi } from '../../helpers/auth-header';

const Auth = new AuthService()

export default class Nav extends Component {

    constructor(props) {
        super(props)
        this.state = {
            nome: '',
            userId: '',
            id_token_phi: '',
            isVisible: false
        }
    }

    componentDidMount() {
        this.getUsername()
        this.getUserId()
    }

    async getUsername() {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/NomeUsuario`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`,                
             }
        })
        .then(resp => resp.json())
        .catch(err => localStorage.getItem('username'))
        .then(data => {
            this.setState({
                ...this.state,
                nome: data
            })
        })
    }

    getUserId() {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/RetornaIdUsuario`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`,                
             }
        })
        .then(resp => resp.json())
        .catch(err => {
            this.setState({
                ...this.state,
                isVisible: false
            })
        })
        .then(data => {
            this.setState({
                ...this.state,
                userId: data,
            })
            this.getUserAccess()
        })
    }    

    async getUserAccess() {

        var userId = '0';

        if (this.state !== null && this.state !== 'undefined')
            userId = this.state.userId;

        const tokenApi = await getHachimonTokenApi();    

        fetch(`${process.env.REACT_APP_SECURITY_URL}/v1/security/verifyUserAccess/system/PHI/idAdminEmpresas/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${tokenApi}`,
            },
        })            
            .then(resp => resp.json())
            .then(data => {
                this.setState({
                    ...this.state,
                    isVisible: data.data ? true : false
                })
                this.generateToken()
            }).catch(err => {
                this.setState({
                    ...this.state,
                    isVisible: false
                })
            })
    }

    handleLogout() {
        Auth.logout()
        this.props.history.replace('/login')
    }

    async generateToken() {
        const tokenApi = await getHachimonTokenApi();

        fetch(`${process.env.REACT_APP_SECURITY_URL}/v1/security/token-generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenApi}`
            },
            body: JSON.stringify({ UserId: this.state.userId, UserLogin: localStorage.getItem('username'), UserName: this.state.nome  })
        })
            .then(resp => resp.json())
            .then(data => { 
                this.setState({
                ...this.state,
                id_token_phi: data.data
            })
        })
    }    

    render() {
        return (
            <aside className="menu-area">
                <nav className="menu">
                    <ReactTooltip
                        place="bottom"
                        multiline={true}
                        border={true}
                        type="info"
                    />
                    <NomeUsuario nome={localStorage.getItem('username')} tooltip={this.state.nome} />
                    <Link to="/home">
                        <i className="fa fa-home"></i> Início
                    </Link>
                    <Link to="/home/usuarios">
                        <i className="fa fa-users"></i> Lista de usuários
                    </Link>
                    <Link to="/home/cadastrar" >
                        <i className="fa fa-user-plus"></i> Cadastrar usuários
                    </Link>
                    <Link to="/home/indice" >
                        <i className="fa fa-home"></i> Dashboards
                    </Link>
                    {this.state.isVisible && <a href={`${process.env.REACT_APP_PHI_URL}/home?token=${this.state.id_token_phi}`} target='_blank' rel='noopener noreferrer' >
                        <i className="fa fa-user-plus"></i> Novo Portal PHI
                    </a>}
                    <Link to="/" onClick={this.handleLogout.bind(this)} className='logout'>
                        <i className="fa fa-power-off"></i> Sair
                    </Link>
                   
                </nav>
            </aside>
        )
    }
}