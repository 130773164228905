import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Modal from 'react-responsive-modal'
import ReactTooltip from 'react-tooltip'
import './AlterarSenha.css'
import { Success, Fail } from '../template/Window';
import Loader from '../template/Loader'

export default class AlterarSenha extends Component {

    constructor(props) {
        super(props)

        this.state = {
            modalSenhaOpen: false,
            password: '',
            passwordre: '',
            postPass: false
        }

        this.onSenhaModal = this.onSenhaModal.bind(this)
        this.onCloseSenhaModal = this.onCloseSenhaModal.bind(this)
        this.onEnviaSenhaModal = this.onEnviaSenhaModal.bind(this)
        this.onCloseEnviaSenhaModal = this.onCloseEnviaSenhaModal.bind(this)
        this.handleChangePass = this.handleChangePass.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChangePass = this.handleInputChangePass.bind(this)
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this)
        this.handleSendPassword = this.handleSendPassword.bind(this)
    }

    onSenhaModal() {
        this.setState({ modalSenhaOpen: true })
    }

    onEnviaSenhaModal() {
        this.setState({ modalEnviaSenhaOpen: true })
    }

    onCloseSenhaModal() {
        this.setState({ modalSenhaOpen: false })
    }

    onCloseEnviaSenhaModal() {
        this.setState({ modalEnviaSenhaOpen: false })
    }

    handleChangePass(event) {
        event.preventDefault();
        var pass = event.target.value;
        var reg = /^([@#](?=[^aeiou]{5}$)(?=[[:alnum:]]{5}$)(?=.*[A-Z]{1,}.*$).+)$/;
        var test = reg.test(pass);
        if (test) {
           Success('pass');
           this.setState({value: pass});
        }else{
            Fail('fail');
        }        
    }

    handleInputChangePass(event) {
        switch (event.target.id) {
            case "password":
                return this.setState({ password: event.target.value })
            case "passwordre":
                return this.setState({ passwordre: event.target.value })
            default:
                return;
        }
    }

    handleUpdatePassword() {
        this.setState({ postPass: true })
        
        let arrPassword = {
            "Id": this.props.Id,
            "Login": this.props.Login,
            "Senha": this.state.password
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/AlterarSenha`, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`
            },
            method: 'PUT',
            body: JSON.stringify(arrPassword)
        })
            .then(response => {
                if (response.status !== 200 && response.status !== 204) {
                    Fail(response.statusText)
                } else {
                    Success('A senha foi alterada com sucesso!')
                    this.onCloseSenhaModal()
                }
                this.setState({ postPass: false })
            })
    }

    handleSendPassword() {
        this.setState({ postPass: true })

        let formBody = {
            email: this.props.Email 
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/EnviarSenhaUsuario`, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`
            },
            method: 'POST',
            body: JSON.stringify(formBody)
        })
            .then(response => {
                if (response.status !== 200 && response.status !== 204) {
                    Fail(response.statusText)
                } else {
                    Success('O email foi enviado com sucesso!')
                    console.log('bouas')
                     this.onCloseEnviaSenhaModal()
                }
                 this.setState({ postPass: false })
            })

    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.password !== '') {
            if(this.state.password === this.state.passwordre){
                if(this.state.password.length < 6 || this.state.password.length > 10) {
                    Fail('A nova senha deve atender aos seguintes critérios:  Conter no mínimo uma letra maiúscula uma letra minúscula e um caractere especial. Conter no total um mínimo de 6 caracteres. Não pode ser igual as últimas senhas anteriores.')
                }else {
                    var pass = this.state.password;
                    var reg = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;
                    var test = reg.test(pass);
                    if (test) {
                            this.handleUpdatePassword()
                    }else{
                        Fail('A nova senha deve atender aos seguintes critérios: Conter no mínimo uma letra maiúscula uma letra minúscula e um caractere especial. Conter no total um mínimo de 6 caracteres. Não pode ser igual as últimas senhas anteriores.');
                    } 
                }        
            } else {
                Fail('Os campos "Nova senha" e "Confirmar senha" devem conter senhas iguais')
            }

        }else {
            Fail('O Campo senha não deve ser vazio')
        }
    }

    render() {
        return (
            <div>
                <div className='row mt-5'>
                    <div className='col-sm-12 col-md-12'>
                        <div className="card">
                            <div className="card-header">
                                Senha de acesso
                                    </div>
                            <div className="card-body card-senha text-center">
                                
                                    <Button variant="contained" size="small" color="secondary" style={{ color: '#ffffff' }} onClick={this.onSenhaModal} >
                                        <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}><i className="fas fa-user-edit"></i></span>Alterar senha de usuário
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button variant="contained" size="small" color="secondary" style={{ color: '#ffffff' }} onClick={this.onEnviaSenhaModal} >
                                        <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}><i className="fas fa-envelope"></i></span>Enviar senha para o usuário
                                    </Button>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <Modal open={this.state.modalSenhaOpen} onClose={this.onCloseSenhaModal} >
                    <div className="content">
                        <h4>Alterar senha do usuário</h4>
                        <hr />
                        <div className="form-group mt-6">
                                <ReactTooltip
                                    place="bottom"
                                    border={false}
                                    type="info"
                                    html={true}
                                />
                            <p>Insira a nova senha e clique em alterar <i data-tip="
                            <h6>Critérios mínimos da nova senha</h6>
                            <p>Conter no mínimo:</p>
                                <ul>
                                    <li>1 letra maiúscula</li>
                                    <li>1 letra minuscula</li>
                                    <li>1 caractere especial</li>
                                    <li>6 caracteres no total</li>
                                </ul>
                            <p>A senha não pode ser igual as últimas senhas anteriores.</p>" className="fas fa-info-circle senha"></i></p>
                        </div>
                        <hr />
                                {!this.state.postPass ? (
                                <div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Nova senha:</label>
                                    <div className="col-sm-6">
                                        <input name='password' id='password' type="password" className="form-control" value={this.state.value} onChange={(e) => this.handleInputChangePass(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Confirmar senha:</label>
                                    <div className="col-sm-6">
                                        <input name='passwordre' id='passwordre' type="password" className="form-control" value={this.state.value} onChange={(e) => this.handleInputChangePass(e)} />
                                    </div>
                                </div>
                       
                                <div className="text-center">
                                    <Button variant="contained" size="small" color="primary" style={{ color: '#ffffff', marginLeft: 15 }}  type="submmit" onClick={this.handleSubmit}>
                                            <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}><i className="fas fa-save"></i></span>Alterar senha
                                    </Button>
                                    

                                </div>
                                </div>
                                ) : (<Loader />)}
                
                        <hr /> 
                    </div>
                </Modal>
                
                <Modal open={this.state.modalEnviaSenhaOpen} onClose={this.onCloseEnviaSenhaModal} >
                    <div className="content">
                        <h4>Enviar senha para o usuário</h4>
                        {!this.state.postPass ? (
                        <div>
                        <p>Tem certeza de que deseja enviar uma nova senha?</p>
                        <p style={{textAlign: 'center'}}>
                        <Button variant="contained" size="small" color="primary" onClick={this.handleSendPassword}>Sim</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="contained" size="small" color="default" onClick={this.onCloseEnviaSenhaModal}>Não</Button>
                        </p>
                        </div>
                         ) : (<Loader />)}
                        <hr />
                        
                    </div>
                </Modal>        
            </div>
        )
    }
}