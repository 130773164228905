import React,{Component} from 'react';
import Main from '../template/Main';
import DadosDoCT from './DadosDoCT';
let headerProps = {
    icon: 'user-plus',
    title: 'Dashboards',
    subtitle: 'Classificar CTs de todos os perfis'
}

export default class Indice extends Component {
    constructor(props) {
        super(props);
        this.renderForm = this.renderForm.bind(this)
    }

    renderForm() {
        return <DadosDoCT />
    }
    render() { 
        return(
            <Main {...headerProps}>
                {this.renderForm()}
            </Main>
        )
    }
}