import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom'

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {
  handleClick = () => {

  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={"Cadastrar novo usuário"}>
          <Link to="/home/cadastrar">
            <IconButton className={classes.iconButton} onClick={this.handleClick}>
              <AddIcon className={classes.deleteIcon} />
            </IconButton>
          </Link>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);