import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'

const alertSettings = {
    position: 'bottom',
    effect: 'stackslide',
    onShow: function () {
    },
    beep: false,
    timeout: 3000,
    offset: 0,
    html: true
}

const htmlTitle = (text) => `<h5><center>${text}</center><h5>`

export function Success(text) 
{
    Alert.success(htmlTitle(text), alertSettings)
}

export function Fail(text) 
{
    Alert.error(htmlTitle(text), alertSettings)
}
