import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch'
import './PermissoesPad.css'

class PermissoesPad extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openAvisoModal: false
        }

        this.onChangeSwitch = this.onChangeSwitch.bind(this)
    }

    onChangeSwitch(indice) {
        let atualizarLista = true;
        let lstCategoria = [...this.props.categorias]

        if(lstCategoria[this.props.indice].Permissoes[indice].Id === 105 || lstCategoria[this.props.indice].Permissoes[indice].Id === 106)
        {
            if(lstCategoria[4].Permissoes[2].ativo === true || lstCategoria[4].Permissoes[5].ativo === true){
                this.props.exibeAvisoModal("Aviso: para selecionar este item, desative a(s) opção(ões) Dash");
                atualizarLista = false;
            }
            else if(lstCategoria[this.props.indice].Permissoes[indice].Id === 105 && lstCategoria[5].Permissoes[1].ativo === true)
            {
                atualizarLista = false;
                this.props.exibeAvisoModal("Aviso: não é possível selecionar mais de uma opção neste item");
            }
            else if(lstCategoria[this.props.indice].Permissoes[indice].Id === 106 && lstCategoria[5].Permissoes[0].ativo === true)
            {
                atualizarLista = false;
                this.props.exibeAvisoModal("Aviso: não é possível selecionar mais de uma opção neste item");
            }
        }

        if(lstCategoria[this.props.indice].Permissoes[indice].Id === 55 || lstCategoria[this.props.indice].Permissoes[indice].Id === 65)
        {
            if(lstCategoria[5].Permissoes[0].ativo === true || lstCategoria[5].Permissoes[1].ativo === true){
                this.props.exibeAvisoModal("Aviso: para selecionar este item, desative a(s) opção(ões) Dashs Médicos");
                atualizarLista = false;
            }
        }

        if(atualizarLista)
        {
            lstCategoria[this.props.indice].Permissoes[indice].ativo = !lstCategoria[this.props.indice].Permissoes[indice].ativo
            this.props.atualizaInformacoesLegadas(lstCategoria[this.props.indice].Permissoes[indice].Id, lstCategoria[this.props.indice].Permissoes[indice].ativo)
            this.props.onUpdateListCat(lstCategoria)
        }
    }

    render() {
        return (
            <div className="card mt-3 largura">
                <div className="card-header">
                    {this.props.NomeCategoria}
                </div>
                <div className="card-body mt-0 pt-2" >
                    <div className='row mt-2'>
                        {Object.keys(this.props.categorias[this.props.indice].Permissoes).map((item, i) => {
                                return (<div className='col-sm-12 col-md-6' key={i}>
                                    <div className='col-md-1 comboSwitch col-1--perpadrao'>
                                        <Switch
                                            checked={this.props.categorias[this.props.indice].Permissoes[item].ativo === true}
                                            onChange={() => this.onChangeSwitch(i)}
                                            color="primary"
                                        />
                                    </div>
                                    <div className='col-md-11 pl-2 col-11--perpadrao'>{this.props.categorias[this.props.indice].Permissoes[item].Nome}</div>
                                </div>);
                        })}

                    </div>
                </div>
            </div>
        )
    }
}

export default PermissoesPad
