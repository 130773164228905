import React, { Component } from 'react'

class LoginBg extends Component {
    render() {
        return (
        <div id="home" className="panel">
            <div id="backgroundchange">
                <div className="backgroundimg" id="back1"></div>
                <div className="backgroundimg" id="back2"></div>
                <div className="backgroundimg" id="back3"></div>
                <div className="backgroundimg" id="back4"></div>
            </div>

        </div>
        )
    }
}

export default LoginBg