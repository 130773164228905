import React, { Component } from 'react'
import loading from '../../assets/imgs/loading.gif'


export default class Loader extends Component {
    render() {
        return (
            <div className='container text-center align-center'>
                <img alt="Icone de carregamento de tela" src={loading} width="100" style={{ marginTop: 50, marginBottom: 50 }} />
            </div>
        )
    }
}