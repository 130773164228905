export function authHeader() {
    // return authorization header with basic auth credentials
    let token = localStorage.getItem('id_token');

    if (token) {
        return { 'Authorization': 'Bearer ' + token};
    } else {
        return {};
    }
}

export async function getHachimonTokenApi(){
    let token

    await fetch(`${process.env.REACT_APP_HACHIMON_URL}/token`, {
        headers: {
            'Content-Type': 'application/json;'
        },
        method: 'POST',
        body: JSON.stringify({ service_hash: process.env.REACT_APP_HASH_KEY })
    })
        .then(r => r.json())
        .then(j => {
            token = j.access_token;
        })

    return token;
}