import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./CadastrarDadosPessoais.css";
import dateFormat from "dateformat";
import Loader from "../template/Loader";
import Customers from "../cadastrar/Customers";
import Permissoes from "./Permissoes";
import AlterarSenha from "./AlterarSenha";
import { Success, Fail } from "../template/Window";
import { Redirect } from "react-router";
import { RequestsClientes } from "../../API/RequestClient";
import AvisoModal from "./ModalAviso";

//MUDAR CORES PRIMARIAS E SECUNDÝRIAS DO MATERIAL.IO (BOTÕES E CHECKBOXES)
const theme = createMuiTheme({
  palette: {
    primary: { main: "#038B9B" }, // Mudar cor primária para cheboxes e botões do material.io.
    secondary: { main: "#11cb5f" }, // Cor secundária, verde próximo ao verde vidalink.
  },
  typography: { useNextVariants: true },
  overrides: {
    MuiButton: {
      contained: {
        color: "white!important",
        backgroundColor: "rgba(185, 45, 88, 1)!important",
        borderRadius: "25px",
      },
      containedPrimary: {
        backgroundColor: "#038B9B!important",
        borderRadius: "25px",
      },
    },
  },
});

let killInterval;

//PEGA A DATA ATUAL
var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substr(0, 10);

export default class CadastrarDadosPessoais extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: [],
      user: [],
      clients: [],
      activeClients: [],
      categorias: [],
      permissaoEdit: [],
      postAction: false,
      perfilSelected: "",
      comboCustomerDesabilitado: false,
      idCustomerSelected: "",
      usuario: "",
      nome: "",
      email: "",
      nomeEmail: "",
      datainicio: undefined,
      datafim: undefined,
      superAdmin: false,
      edit: false,
      relatorioGerenciais: false,
      InclusaoVida: false,
      AlteracaoVida: false,
      AtivacaoVida: false,
      AlteracaoLimite: false,
      ConPesquisaProduto: false,
      ConDemoFinanceiro: false,
      ConPesquisa: false,
      ConInclusao: false,
      ConAltVida: false,
      ConEnvPedido: false,
      msgTooltip:
        "Selecione um tipo de usuário para<br />ler a descrição de cada item",
      liberacaoEspecial: false,
      pilares: [],
      openAvisoModal: false,
      button: "Ok, entendi",
      text: "",
    };

    this.loadValue = this.loadValue.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.postForm = this.postForm.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.getProximoLogin = this.getProximoLogin.bind(this);
    this.getTipoUser = this.getTipoUser.bind(this);
    this.updateClient = this.updateClient.bind(this);
    this.updateCustomerSelected = this.updateCustomerSelected.bind(this);
    this.getListaCategorias = this.getListaCategorias.bind(this);
    this.addStatusInListCat = this.addStatusInListCat.bind(this);
    this.onUpdateListCat = this.onUpdateListCat.bind(this);
    this.postPermissao = this.postPermissao.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.getListaCategoriaUsuario = this.getListaCategoriaUsuario.bind(this);
    this.getClient = this.getClient.bind(this);
    this.addStatusInList = this.addStatusInList.bind(this);
    this.desabilitaTodosPerfils = this.desabilitaTodosPerfils.bind(this);
    this.atualizaInformacoesLegadas =
      this.atualizaInformacoesLegadas.bind(this);
    this.onAtivaInativaRelatorioGerenciais =
      this.onAtivaInativaRelatorioGerenciais.bind(this);
    this.editUser = this.editUser.bind(this);
    this.editPermissao = this.editPermissao.bind(this);
    this.mudarTextoTooltip = this.mudarTextoTooltip.bind(this);
    this.verificaEditAdmin = this.verificaEditAdmin.bind(this);
    this.ValoresDefault = this.ValoresDefault.bind(this);
    this.atualizarStatusCategoria = this.atualizarStatusCategoria.bind(this);
    this.getLiberacaoEspecial = this.getLiberacaoEspecial.bind(this);
    this.exibeAvisoModal = this.exibeAvisoModal.bind(this);
    this.setActiveClients = this.setActiveClients.bind(this);
  }

  ValoresDefault(categorias, tipo) {
    let data = {
      AD: [
        {
          Id: 1,
          Permissoes: [
            { Id: 32 },
            { Id: 60 },
            { Id: 63 },
            { Id: 78 },
            { Id: 90 },
          ],
        },
        { Id: 2, Permissoes: [{ Id: 69 }, { Id: 70 }, { Id: 71 }, { Id: 72 }] },
        { Id: 3, Permissoes: [{ Id: 67 }, { Id: 68 }] },
        {
          Id: 4,
          Permissoes: [
            { Id: 73 },
            { Id: 74 },
            { Id: 75 },
            { Id: 76 },
            { Id: 87 },
            { Id: 88 },
          ],
        },
        {
          Id: 5,
          Permissoes: [
            { Id: 36 },
            { Id: 37 },
            { Id: 55 },
            { Id: 58 },
            { Id: 64 },
            { Id: 65 },
          ],
        },
        { Id: 7, Permissoes: [{ Id: 80 }] },
        { Id: 8, Permissoes: [{ Id: 83 }, { Id: 84 }, { Id: 85 }] },
        { Id: 9, Permissoes: [{ Id: 81 }, { Id: 82 }, { Id: 91 }] },
        { Id: 10, Permissoes: [{ Id: 86 }, { Id: 89 }] },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
      CS: [
        {
          Id: 4,
          Permissoes: [
            { Id: 73 },
            { Id: 74 },
            { Id: 75 },
            { Id: 76 },
            { Id: 87 },
            { Id: 88 },
          ],
        },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
      RH: [
        {
          Id: 1,
          Permissoes: [
            { Id: 32 },
            { Id: 60 },
            { Id: 63 },
            { Id: 78 },
            { Id: 90 },
          ],
        },
        { Id: 2, Permissoes: [{ Id: 69 }, { Id: 70 }, { Id: 71 }, { Id: 72 }] },
        { Id: 3, Permissoes: [{ Id: 67 }] },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
      MD: [
        {
          Id: 1,
          Permissoes: [
            { Id: 32 },
            { Id: 78 },
            { Id: 81 },
            { Id: 82 },
            { Id: 83 },
            { Id: 84 },
            { Id: 85 },
            { Id: 86 },
          ],
        },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
      PI: [
        { Id: 5, Permissoes: [{ Id: 36 }, { Id: 58 }] },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
      SA: [
        {
          Id: 1,
          Permissoes: [
            { Id: 32 },
            { Id: 60 },
            { Id: 63 },
            { Id: 78 },
            { Id: 90 },
          ],
        },
        { Id: 2, Permissoes: [{ Id: 69 }, { Id: 70 }, { Id: 71 }, { Id: 72 }] },
        { Id: 3, Permissoes: [{ Id: 67 }, { Id: 68 }] },
        {
          Id: 4,
          Permissoes: [
            { Id: 73 },
            { Id: 74 },
            { Id: 75 },
            { Id: 76 },
            { Id: 87 },
            { Id: 88 },
          ],
        },
        {
          Id: 5,
          Permissoes: [
            { Id: 36 },
            { Id: 37 },
            { Id: 55 },
            { Id: 58 },
            { Id: 64 },
            { Id: 65 },
          ],
        },
        { Id: 7, Permissoes: [{ Id: 80 }] },
        { Id: 8, Permissoes: [{ Id: 83 }, { Id: 84 }, { Id: 85 }] },
        { Id: 9, Permissoes: [{ Id: 81 }, { Id: 82 }, { Id: 91 }] },
        { Id: 10, Permissoes: [{ Id: 86 }, { Id: 89 }] },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
      VO: [
        {
          Id: 1,
          Permissoes: [
            { Id: 32 },
            { Id: 60 },
            { Id: 63 },
            { Id: 78 },
            { Id: 90 },
          ],
        },
        { Id: 2, Permissoes: [{ Id: 69 }, { Id: 70 }, { Id: 71 }, { Id: 72 }] },
        { Id: 8, Permissoes: [{ Id: 83 }, { Id: 84 }, { Id: 85 }] },
        { Id: 9, Permissoes: [{ Id: 81 }, { Id: 82 }, { Id: 91 }] },
        { Id: 10, Permissoes: [{ Id: 86 }, { Id: 89 }] },
        {
          Id: 13,
          Permissoes: [{ Id: 97 }, { Id: 98 }, { Id: 99 }, { Id: 100 }],
        },
        { Id: 14, Permissoes: [{ Id: 101 }] },
      ],
    };

    //Ativa relatorio Gerenciais
    //if(tipo === 'RH' || tipo === 'MD')
    //  this.setState({ relatorioGerenciais: true})
    //else
    this.setState({ relatorioGerenciais: false });

    this.atualizarStatusCategoria(categorias, data[tipo]);
  }

  componentDidMount() {
    this.getTipoUser();
    if (this.props.retornaId() !== undefined) {
      this.setState({ edit: true });
      this.loadUser();
      setTimeout(() => {
        clearInterval(killInterval);
      }, 1000);
    } else {
      this.getProximoLogin();
      this.setState({
        edit: false,
        nome: "",
      });
    }
  }

  componentWillReceiveProps() {
    this.setState({
      page: [],
      clients: [],
      categorias: [],
      permissaoEdit: [],
      postAction: false,
      perfilSelected: "",
      comboCustomerDesabilitado: false,
      idCustomerSelected: "",
      usuario: "",
      nome: "",
      email: "",
      nomeEmail: "",
      datainicio: undefined,
      datafim: undefined,
      superAdmin: false,
      edit: false,
      relatorioGerenciais: false,
      InclusaoVida: false,
      AlteracaoVida: false,
      AtivacaoVida: false,
      AlteracaoLimite: false,
      ConPesquisaProduto: false,
      ConDemoFinanceiro: false,
      ConPesquisa: false,
      ConInclusao: false,
      ConAltVida: false,
      ConEnvPedido: false,
      msgTooltip:
        "Selecione um tipo de usuário para<br />ler a descrição de cada item",
      liberacaoEspecial: false,
    });
    this.getProximoLogin();
  }

  handleCallback = (childData) => {
    this.setState({ openAvisoModal: childData });
  };

  setActiveClients(client, isAddPrevState) {
    if (isAddPrevState) {
      this.setState((prevState) => ({
        activeClients: [...prevState.activeClients, client],
      }));
    } else {
      this.setState({ activeClients: client });
    }
  }

  setRemoveClients(client) {
    this.setState(client);
  }

  getProximoLogin() {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/proximologin`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) =>
        this.setState({
          ...this.state,
          page: data,
        })
      );
  }

  getTipoUser() {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/Tipo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) =>
        this.setState({
          ...this.state,
          user: this.renomearChaves(data),
        })
      );
  }

  getListaCategorias(idPerfil, ct) {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/permissao/categoria?prefixoTipo=${idPerfil}&ct=${ct}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        this.renomearChaves(data);
        this.addStatusInListCat(data, idPerfil);
      });
  }

  getListaCategoriaUsuario(listCategoriaAtual) {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/permissao/usuario/` +
        this.props.retornaId(),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        this.renomearChaves(data);
        this.atualizarStatusCategoria(listCategoriaAtual, data);
      });
  }

  atualizarStatusCategoria(listCategoriaAtual, novaListCategoria) {
    let lstAuxiliar = novaListCategoria;
    for (let i = 0; i < listCategoriaAtual.length; i++) {
      for (let a = 0; a < lstAuxiliar.length; a++) {
        if (listCategoriaAtual[i].Id === lstAuxiliar[a].Id) {
          for (let x = 0; x < listCategoriaAtual[i].Permissoes.length; x++) {
            for (let y = 0; y < lstAuxiliar[a].Permissoes.length; y++) {
              if (
                listCategoriaAtual[i].Permissoes[x].Id ===
                lstAuxiliar[a].Permissoes[y].Id
              ) {
                listCategoriaAtual[i].Permissoes[x].ativo = true;
                this.atualizaInformacoesLegadas(
                  listCategoriaAtual[i].Permissoes[x].Id,
                  true
                );
              }
            }
          }
        }
        //Lista Categoria Omitida
        else if (lstAuxiliar[a].Id === 6) {
          for (let y = 0; y < lstAuxiliar[a].Permissoes.length; y++)
            this.atualizaInformacoesLegadas(
              lstAuxiliar[a].Permissoes[y].Id,
              true
            );
        }
      }
    }

    this.setState({ categorias: listCategoriaAtual });
  }

  async addStatusInListCat(data, idPerfil) {
    let listCategoria = JSON.parse(JSON.stringify(data));
    let result = await RequestsClientes.getChatBotPillarApi();
    this.setState({ pilares: result });

    for (let i = 0; i < data.length; i++) {
      for (let a = 0; a < data[i].Permissoes.length; a++) {
        if (data[i].Permissoes[a].PilarId !== undefined) {
          let result = await RequestsClientes.verifyIdPilar(
            data[i].Permissoes[a].PilarId,
            this.state.pilares
          );

          if (result === null) {
            let novaLista = listCategoria[i].Permissoes.findIndex(
              (item) => item.Id === data[i].Permissoes[a].Id
            );
            listCategoria[i].Permissoes.splice(novaLista, 1);
          }
        }
      }
    }

    for (let i = 0; i < listCategoria.length; i++) {
      for (let a = 0; a < listCategoria[i].Permissoes.length; a++) {
        listCategoria[i].Permissoes[a].Ativo = false;
      }
    }

    if (this.state.edit) {
      this.getListaCategoriaUsuario(listCategoria);
    } else {
      // this.setState({ categorias: listCategoria })
      this.ValoresDefault(listCategoria, idPerfil);
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  mudarTextoTooltip(tipo) {
    switch (tipo) {
      case "RH":
        return this.setState({
          msgTooltip:
            "Cliente que possui acesso restrito ao portal. <br />Não possui visualização dos medicamentos <br />no extrato do usuário (RH).",
        });
      case "VO":
        return this.setState({
          msgTooltip:
            "Equipe de atendimento ao cliente que possui acesso <br />completo ao portal. Além disso, possui liberação <br />para visualização dos medicamentos no extrato de <br />compras dos usuários (RHI).",
        });
      case "PI":
        return this.setState({
          msgTooltip:
            "Clientes que possuem acesso aos relatórios <br />gerenciais da indústria com visualização dos <br />medicamentos adquiridos pelos usuários (RHI).",
        });
      case "CR":
        return this.setState({ msgTooltip: "" });
      case "CS":
        return this.setState({
          msgTooltip:
            "Clientes que possuem acesso a cadastro de <br />pacientes e solicitação de medicamentos <br />de alto custo. O mesmo possui acesso aos <br />demonstrativos financeiros com visualização <br />dos medicamentos adquiridos (RHI).",
        });
      case "MD":
        return this.setState({
          msgTooltip:
            "Usuários com acesso aos  relatórios <br />gerenciais, podendo visualizar o <br />nome dos medicamento adquiridos.",
        });
      case "AD":
        return this.setState({
          msgTooltip: "Usuário que possui acesso total ao portal.",
        });
      case "SA":
        return this.setState({
          msgTooltip:
            "Usuário que possui acesso total ao portal. <br />Além disso, pode criar novos Administradores.",
        });
      default:
        return this.setState({
          msgTooltip:
            "Selecione um tipo de usuário para<br />ler a descrição de cada item",
        });
    }
  }

  handleInputChange(event) {
    switch (event.target.id) {
      case "nome":
        return this.setState({ nome: event.target.value });
      case "email":
        return this.setState({ email: event.target.value });
      case "datafim":
        return this.setState({ datafim: event.target.value });
      case "nomeEmail":
        return this.state.edit
          ? this.setState({ email: event.target.value })
          : this.setState({ nomeEmail: event.target.value });
      default:
        return;
    }
  }

  atualizaInformacoesLegadas(id, status) {
    switch (id) {
      case 69:
        return this.setState({ InclusaoVida: status });
      case 70:
        return this.setState({ AlteracaoVida: status });
      case 71:
        return this.setState({ AtivacaoVida: status });
      case 72:
        return this.setState({ AlteracaoLimite: status });
      case 73:
        return this.setState({ ConInclusao: status });
      case 74:
        return this.setState({ ConPesquisa: status });
      case 75:
        return this.setState({ ConPesquisaProduto: status });
      case 76:
        return this.setState({ ConDemoFinanceiro: status });
      case 77:
        return this.setState({ relatorioGerenciais: status });
      case 87:
        return this.setState({ ConAltVida: status });
      case 88:
        return this.setState({ ConEnvPedido: status });
      default:
        return;
    }
  }

  handleErrors(response) {
    if (!response.ok) throw Error(response.data);
    return response;
  }

  updateClient(listClient) {
    this.setState({ clients: listClient });
  }

  updateCustomerSelected(id) {
    this.setState({ idCustomerSelected: id });
  }

  onUpdateListCat(lstCategoria) {
    this.setState({ categorias: lstCategoria });
  }

  onAtivaInativaRelatorioGerenciais() {
    let status = !this.state.relatorioGerenciais;

    if (status) this.desabilitaTodosPerfils();

    this.setState({ relatorioGerenciais: status });
  }

  desabilitaTodosPerfils() {
    let listCategoria = [...this.state.categorias];
    for (let i = 0; i < listCategoria.length; i++) {
      for (let a = 0; a < listCategoria[i].Permissoes.length; a++) {
        listCategoria[i].Permissoes[a].ativo = false;
      }
    }

    this.setState({
      categorias: listCategoria,
      InclusaoVida: false,
      AlteracaoVida: false,
      AtivacaoVida: false,
      AlteracaoLimite: false,
    });
  }

  //CADASTRO DE NOVOS USUÁRIOS
  postForm(e) {
    e.preventDefault();
    let listClients = JSON.parse(JSON.stringify(this.state.clients));
    let CustomerId = this.state.idCustomerSelected;
    let TodosClients = this.state.activeClients.length === 0 ? true : false;

    for (let i = this.state.clients.length - 1; i >= 0; i--) {
      let client = this.state.clients[i];
      if (!client.ativo) {
        listClients.splice(i, 1);
        TodosClients = false;
      } else {
        for (let a = this.state.clients[i].Groups.length - 1; a >= 0; a--) {
          let group = client.Groups[a];
          if (!group.ativo) {
            listClients[i].Groups.splice(a, 1);
            listClients[i].todos = false;
          }
        }
      }
    }

    this.setState({ postAction: true });
    let permissoes = [];
    if (this.state.perfilSelected === "VO") {
      permissoes.push({ Id: 59 });
    } else {
      permissoes.push({ Id: 30 });
    }

    if (this.state.relatorioGerenciais) permissoes.push({ Id: 77 });

    for (let i = 0; i < this.state.categorias.length; i++) {
      for (let a = 0; a < this.state.categorias[i].Permissoes.length; a++) {
        if (this.state.categorias[i].Permissoes[a].ativo === true)
          permissoes.push({ Id: this.state.categorias[i].Permissoes[a].Id });
      }
    }
    // FIM ARRUMAR

    //let listClients = JSON.parse(JSON.stringify(this.state.clients))
    // let CustomerId = this.state.idCustomerSelected
    // let TodosClients = true

    // for (let i = this.state.clients.length - 1; i >= 0; i--) {
    //     let client = this.state.clients[i]
    //     if (!client.Ativo) {
    //         listClients.splice(i, 1);
    //         TodosClients = false;
    //     }else{
    //         for (let a = this.state.clients[i].Groups.length - 1; a >= 0; a--) {
    //             let group = client.Groups[a];
    //             if (!group.Ativo) {
    //                 listClients[i].Groups.splice(a, 1);
    //                 listClients[i].Todos = false
    //             }
    //         }
    //     }
    // }

    this.setState({ postAction: true });
    let valorEmail = "";
    if (this.state.superAdmin) {
      valorEmail = this.state.nomeEmail + "@vidalink.com.br";
    } else {
      valorEmail = this.state.email;
    }

    let details = {
      Nome: this.state.nome,
      TipoUsuario: { Prefixo: this.state.perfilSelected },
      Login: this.state.usuario,
      Email: valorEmail,
      Ct: {
        Id: CustomerId,
        Clients: this.state.activeClients,
        Todos: TodosClients,
      },
      customer: {
        Id: CustomerId,
        Clients: this.state.activeClients,
        Todos: TodosClients,
      },
      DataInativacao: this.state.datafim,
      Permissoes: {
        Id: 0,
        Permissoes: permissoes,
        //Linhas abaixo para atender sistema Legado
        RelatorioGerenciais: this.state.relatorioGerenciais,
        InclusaoVidas: this.state.InclusaoVida,
        AlteracaoVidas: this.state.AlteracaoVida,
        AlteracaoLimites: this.state.AlteracaoLimite,
        AtivacaoVidas: this.state.AtivacaoVida,
        ConPesquisaProduto: this.state.ConPesquisaProduto,
        ConDemoFinanceiro: this.state.ConDemoFinanceiro,
        ConPesquisa: this.state.ConPesquisa,
        ConInclusao: this.state.ConInclusao,
        ConAltVida: this.state.ConAltVida,
        ConEnvPedido: this.state.ConEnvPedido,
      },
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
      method: "POST",
      body: JSON.stringify(details),
    })
      .then((response) => {
        if (response.status !== 200 && response.status !== 204) {
          Fail(response.statusText);
          this.setState({ postAction: false });
        } else {
          return response.json();
        }

        this.props.handleRedirect();
        this.setState({ postAction: false });
      })
      .then((data) => {
        this.postPermissao(data);
      });
  }

  postPermissao(idUsuario) {
    let permissoes = [];
    if (this.state.perfilSelected === "VO") {
      permissoes.push({ Id: 59 });
    } else {
      permissoes.push({ Id: 30 });
    }

    if (this.state.relatorioGerenciais) permissoes.push({ Id: 77 });

    for (let i = 0; i < this.state.categorias.length; i++) {
      for (let a = 0; a < this.state.categorias[i].Permissoes.length; a++) {
        if (this.state.categorias[i].Permissoes[a].ativo === true)
          permissoes.push({ Id: this.state.categorias[i].Permissoes[a].Id });
      }
    }

    let usuarioPermissao = {
      Id: idUsuario,
      Permissoes: permissoes,

      //Linhas abaixo para atender sistema Legado
      RelatorioGerenciais: this.state.relatorioGerenciais,
      InclusaoVidas: this.state.InclusaoVida,
      AlteracaoVidas: this.state.AlteracaoVida,
      AlteracaoLimites: this.state.AlteracaoLimite,
      AtivacaoVidas: this.state.AtivacaoVida,
      ConPesquisaProduto: this.state.ConPesquisaProduto,
      ConDemoFinanceiro: this.state.ConDemoFinanceiro,
      ConPesquisa: this.state.ConPesquisa,
      ConInclusao: this.state.ConInclusao,
      ConAltVida: this.state.ConAltVida,
      ConEnvPedido: this.state.ConEnvPedido,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/permissao/usuario`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
      method: "POST",
      body: JSON.stringify(usuarioPermissao),
    }).then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        Fail(response.statusText);
        this.setState({ postAction: false });
      } else {
        Success("Cadastro realizado com sucesso!");
      }

      this.props.handleRedirect();
      this.setState({ postAction: false });
    });
  }
  //FIM CADASTRO DE NOVOS USUÁRIOS

  //EDIÇÃO DE USUÁRIOS
  editUser(e) {
    //ARRUMAR
    e.preventDefault();
    let listClients = JSON.parse(JSON.stringify(this.state.clients));
    let CustomerId = this.state.idCustomerSelected;
    let TodosClients = true;

    /* for (let i = this.state.clients.length - 1; i >= 0; i--) {
      let client = this.state.clients[i];
      if (!client.ativo) {
        listClients.splice(i, 1);
        TodosClients = false;
      } else {
        for (let a = this.state.clients[i].Groups.length - 1; a >= 0; a--) {
          let group = client.Groups[a];
          if (!group.ativo) {
            listClients[i].Groups.splice(a, 1);
            listClients[i].todos = false;
          }
        }
      }
    } */

    this.setState({ postAction: true });
    let permissoes = [];
    if (this.state.perfilSelected === "VO") {
      permissoes.push({ Id: 59 });
    } else {
      permissoes.push({ Id: 30 });
    }

    if (this.state.relatorioGerenciais) permissoes.push({ Id: 77 });

    for (let i = 0; i < this.state.categorias.length; i++) {
      for (let a = 0; a < this.state.categorias[i].Permissoes.length; a++) {
        if (this.state.categorias[i].Permissoes[a].ativo === true)
          permissoes.push({ Id: this.state.categorias[i].Permissoes[a].Id });
      }
    }
    // FIM ARRUMAR

    let valorEmail = "";
    if (this.state.superAdmin) {
      if (
        this.state.perfilSelected === "AD" ||
        this.state.perfilSelected === "SA"
      ) {
        valorEmail = this.state.email + "@vidalink.com.br";
      } else {
        valorEmail = this.state.nomeEmail + "@vidalink.com.br";
      }
    } else {
      valorEmail = this.state.email;
    }

    let details = {
      Id: this.props.retornaId(),
      Nome: this.state.nome,
      TipoUsuario: { Prefixo: this.state.perfilSelected },
      Login: this.state.usuario,
      Email: valorEmail,
      Ct: {
        Id: CustomerId,
        Clients: this.state.activeClients,
        //voltar pra TodosClients depois
        Todos: false,
      },
      Customer: {
        Id: CustomerId,
        Clients: this.state.activeClients,
        //voltar pra TodosClients depois
        Todos: false,
      },
      DataInativacao: this.state.datafim,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
      method: "PUT",
      body: JSON.stringify(details),
    }).then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        Fail(response.statusText);
        this.setState({ postAction: false });
      } else {
        this.editPermissao(this.props.retornaId());
      }

      this.props.handleRedirect();
      this.setState({ postAction: false });
    });
  }

  editPermissao(idUsuario) {
    let permissoes = [];
    if (this.state.perfilSelected === "VO") {
      permissoes.push({ Id: 59 });
    } else {
      permissoes.push({ Id: 30 });
    }

    if (this.state.relatorioGerenciais) permissoes.push({ Id: 77 });

    for (let i = 0; i < this.state.categorias.length; i++) {
      for (let a = 0; a < this.state.categorias[i].Permissoes.length; a++) {
        if (this.state.categorias[i].Permissoes[a].ativo === true)
          permissoes.push({ Id: this.state.categorias[i].Permissoes[a].Id });
      }
    }

    let usuarioPermissao = {
      Id: idUsuario,
      Permissoes: permissoes,
      //Linhas abaixo para atender sistema Legado
      RelatorioGerenciais: this.state.relatorioGerenciais,
      InclusaoVidas: this.state.InclusaoVida,
      AlteracaoVidas: this.state.AlteracaoVida,
      AlteracaoLimites: this.state.AlteracaoLimite,
      AtivacaoVidas: this.state.AtivacaoVida,
      ConPesquisaProduto: this.state.ConPesquisaProduto,
      ConDemoFinanceiro: this.state.ConDemoFinanceiro,
      ConPesquisa: this.state.ConPesquisa,
      ConInclusao: this.state.ConInclusao,
      ConAltVida: this.state.ConAltVida,
      ConEnvPedido: this.state.ConEnvPedido,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/permissao/usuario`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
      method: "PUT",
      body: JSON.stringify(usuarioPermissao),
    }).then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        Fail(response.statusText);
        this.setState({ postAction: false });
      } else {
        Success("Usuário atualizado com sucesso!");
      }

      this.props.handleRedirect();
      this.setState({ postAction: false });
    });
  }
  //FIM DE EDIÇÃO DE USUÁRIOS

  //FUNÇAO QUE GERA USUARIOS
  loadValue(v) {
    let isSuper = false;
    if (v.target.value === "AD" || v.target.value === "SA") isSuper = true;

    this.setState({
      ...this.state,
      superAdmin: isSuper,
      email: isSuper ? "" : this.state.email,
      nomeEmail: isSuper ? this.state.nomeEmail : "",
      usuario: v.target.value + this.state.page,
      perfilSelected: v.target.value,
    });

    this.getListaCategorias(v.target.value);
    this.mudarTextoTooltip(v.target.value);
  }

  //FUNÇÃO QUE CARREGA DADOS DO USUÁRIO NA TELA DE EDIÇÃO
  loadUser() {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/usuario/` +
        this.props.retornaId(),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        this.renomearChaves(data);
        let email = data.Email;
        this.getListaCategorias(data.TipoUsuario.Prefixo, data.Ct.Id);
        this.getClient(data.Ct.Id, data.Ct.Clients);
        this.getLiberacaoEspecial(data.Ct.Id);

        if (
          email &&
          (data.TipoUsuario.Prefixo === "SA" ||
            data.TipoUsuario.Prefixo === "AD")
        )
          email = data.Email.split("@")[0];

        this.setState({
          ...this.state,
          activeClients: data.Ct.Clients,
          nome: this.state.edit ? data.Nome : "",
          usuario: data.Login,
          email: email,
          perfilSelected: data.TipoUsuario.Prefixo,
          idCustomerSelected: data.Ct.Id,
          datainicio: dateFormat(data.DataCriacao, "yyyy-mm-dd"),
          datafim: this.verificaInativacao(
            dateFormat(data.DataInativacao, "yyyy-mm-dd")
          ),
          permissaoEdit: data,
        });

        this.verificaEditAdmin();
        this.mudarTextoTooltip(data.TipoUsuario.Prefixo);
      });
  }

  getClient(prop, list) {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/customer/${prop}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.addStatusInList(data, list);
      });
  }

  getLiberacaoEspecial(ct) {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/permissao/${ct}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("id_token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (
          data != null &&
          data !== [] &&
          data !== undefined &&
          data.length !== 0
        ) {
          this.setState({ liberacaoEspecial: true });
        } else {
          this.setState({ liberacaoEspecial: false });
        }
      });
  }

  renomearChaves(e) {
    if (Array.isArray(e)) {
      for (var i = 0; i <= e.length; i++) {
        this.renomearChaves(e[i]);
      }
    } else {
      for (var key in e) {
        if (typeof e[key] === typeof {}) {
          this.renomearChaves(e[key]);
        }

        if (key === "customer") {
          e["Ct"] = e[key];
          delete e[key];
        } else if (key.charAt(0).toUpperCase() + key.substring(1) !== key) {
          e[key.charAt(0).toUpperCase() + key.substring(1)] = e[key];
          delete e[key];
        }
      }
    }
    return e;
  }

  addStatusInList(data, list) {
    let listClient = this.renomearChaves(data);

    for (let i = 0; i < listClient.length; i++) {
      listClient[i].ativo = !this.state.edit;
      listClient[i].todos = true;
      for (let a = 0; a < listClient[i].Groups.length; a++) {
        listClient[i].Groups[a].ativo = !this.state.edit;
      }
    }

    if (this.state.edit) {
      listClient = data;
      for (let i = 0; i < listClient.length; i++) {
        for (let a = 0; a < list.length; a++) {
          if (list[a].id === "T") {
            listClient[i].ativo = true;
            for (let x = 0; x < listClient[i].Groups.length; x++) {
              listClient[i].Groups[x].ativo = true;
            }
          } else if (listClient[i].id === list[a].id) {
            listClient[i].ativo = true;
            for (let x = 0; x < listClient[i].Groups.length; x++) {
              for (let y = 0; y < list[a].Groups.length; y++) {
                if (list[a].Groups[y].Id === "T")
                  listClient[i].Groups[x].ativo = true;
                else if (listClient[i].Groups[x].id === list[a].groups[y].id)
                  listClient[i].Groups[x].ativo = true;
              }
            }
          }
        }
      }
    }

    this.updateClient(listClient);
  }

  verificaNull = (e) => {
    return e === undefined ? "-" : e;
  };

  verificaInativacao = (i) => {
    return i === "9999-01-01" ? "-" : i;
  };

  verificaEditAdmin() {
    if (this.state.edit) {
      if (
        this.state.perfilSelected === "AD" ||
        this.state.perfilSelected === "SA"
      ) {
        this.setState({ superAdmin: true });
      }
    }
  }

  exibeAvisoModal(texto) {
    this.setState({ text: texto });
    this.setState({ openAvisoModal: true });
  }

  render() {
    if (this.state.postAction) {
      return <Loader />;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className="col-sm-12 col-md-12">
          <form name="cadastro" onSubmit={this.postForm}>
            {/*forçar redirect se admin tentar editar superadmin*/}
            {localStorage.getItem("username").substring(0, 2) === "AD" &&
            this.state.perfilSelected === "SA" ? (
              <Redirect to="/home/usuarios" />
            ) : (
              ""
            )}

            {/* dados pessoais */}
            <div className="row mt-3">
              <div className="col-sm-12 col-md-12">
                <h6 className="titulo">DADOS PESSOAIS</h6>
                <hr />
              </div>
              <div className="col-sm-12 col-md-3">
                <select
                  name="perfil"
                  value={this.state.perfilSelected}
                  id="perfil"
                  onChange={this.loadValue}
                  className="form-control"
                  required
                  disabled={this.state.edit}
                >
                  <option value="">Selecione o tipo</option>
                  {localStorage.getItem("username").substring(0, 2) === "AD"
                    ? Object.keys(
                        this.state.user.sort(function (a, b) {
                          return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        })
                      ).map((item, i) =>
                        this.state.user[item].Prefixo === "SA" ? (
                          ""
                        ) : (
                          <option value={this.state.user[item].Prefixo} key={i}>
                            {this.state.user[item].Nome}
                          </option>
                        )
                      )
                    : Object.keys(
                        this.state.user.sort(function (a, b) {
                          return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        })
                      ).map((item, i) => (
                        <option value={this.state.user[item].Prefixo} key={i}>
                          {this.state.user[item].Nome}
                        </option>
                      ))}
                </select>
              </div>
              <div className="col-sm-12 col-md-3">
                <ReactTooltip
                  place="bottom"
                  multiline={true}
                  border={true}
                  type="info"
                />
                <div
                  className="alert alert-info"
                  style={{ padding: 6 }}
                  role="alert"
                  data-tip={this.state.msgTooltip + ""}
                >
                  <i className="fa fa-info-circle mr-2 text-info"></i>Tipos de
                  Usuário
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <input
                  name="usuario"
                  id="usuario"
                  type="text"
                  className="form-control"
                  placeholder="Usuário"
                  value={this.state.usuario}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <input
                  name="nome"
                  id="nome"
                  maxLength="100"
                  minLength="2"
                  type="text"
                  className="form-control"
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.nome}
                  placeholder="Nome"
                  required
                />
              </div>
              {this.state.superAdmin ? (
                <div className="col-sm-12 col-md-6">
                  <div id="emailVidalink" className="input-groups">
                    <input
                      name="nomeEmail"
                      id="nomeEmail"
                      type="text"
                      className="form-control"
                      placeholder="Nome email"
                      onChange={(e) => this.handleInputChange(e)}
                      value={
                        this.state.edit
                          ? this.state.email.split("@")[0]
                          : this.state.nomeEmail
                      }
                      maxLength="20"
                      style={{
                        width: "250px!important",
                        float: "left",
                        marginRight: "-1px",
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">
                        @vidalink.com.br
                      </span>
                    </div>
                    <small>
                      Apenas e-mails da Vidalink podem ter acesso de
                      administrador
                    </small>
                  </div>
                </div>
              ) : (
                <div className="col-sm-12 col-md-6">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => this.handleInputChange(e)}
                    value={this.state.email}
                    required
                  />
                </div>
              )}
            </div>
            {/* gestao de perfil */}
            <div className="row mt-5">
              <div className="col-sm-12 col-md-12">
                <h6 className="titulo">GESTÃO DE PERFIL</h6>
                <hr />
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="card">
                  <div className="card-header">Inativação de login</div>
                  <div className="card-body">
                    <p className="card-text small">
                      As datas abaixo refletem o início e o término do acesso ao
                      sistema
                    </p>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 small">
                        Início
                        <br />
                        <div className="input-group input-group-sm mb-3">
                          <input
                            name="datainicio"
                            defaultValue={date}
                            onChange={this.handleInputChange}
                            value={this.state.datainicio}
                            id="datainicio"
                            className="form-control"
                            type="date"
                            disabled
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              <i className="fa fa-calendar text-info"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 small">
                        Fim
                        <br />
                        <div className="input-group input-group-sm mb-3">
                          <input
                            name="datafim"
                            id="datafim"
                            min={date}
                            value={this.state.datafim}
                            onChange={this.handleInputChange}
                            className="form-control"
                            type="date"
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              <i className="fa fa-calendar text-info"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Customers
                idCustomerSelected={this.state.idCustomerSelected}
                clients={this.state.clients}
                activeClients={this.state.activeClients}
                setActiveClients={this.setActiveClients}
                edit={this.state.edit}
                onUpdateClient={this.updateClient}
                onUpdateCustomerSelected={this.updateCustomerSelected}
                getClient={this.getClient}
                getLiberacaoEspecial={this.getLiberacaoEspecial}
              />
            </div>

            {/* alteração de senha */}
            {this.props.retornaId() !== undefined ? (
              <AlterarSenha
                Login={this.state.permissaoEdit.Login}
                Id={this.state.permissaoEdit.Id}
                Email={this.state.email}
              />
            ) : (
              ""
            )}

            {/* permissões */}
            {this.state.categorias.length === 0 ? (
              ""
            ) : (
              <Permissoes
                exibeAvisoModal={this.exibeAvisoModal}
                pilares={this.state.pilares}
                categorias={this.state.categorias}
                onUpdateListCat={this.onUpdateListCat}
                relatorioGerenciais={this.state.relatorioGerenciais}
                onAtivaInativaRelatorioGerenciais={
                  this.onAtivaInativaRelatorioGerenciais
                }
                atualizaInformacoesLegadas={this.atualizaInformacoesLegadas}
                liberacaoEspecial={this.state.liberacaoEspecial}
              />
            )}
            <div className="row mt-5 mb-5">
              <div className="col-md-12 text-center">
                {/* <Button variant="contained" size="large" color="primary" onClick={this.postForm}> */}
                {!this.state.edit ? (
                  <Button
                    id="insert-btn"
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submmit"
                    onSubmit={this.postForm}
                  >
                    <span style={{ fontSize: 20, marginRight: 4 }}>
                      <i className="fas fa-save"></i>
                    </span>
                    Cadastrar
                  </Button>
                ) : (
                  <Button
                    id="update-btn"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={this.editUser}
                  >
                    <span style={{ fontSize: 20, marginRight: 0 }}>
                      <i className="fas fa-save"></i>
                    </span>
                    Atualizar
                  </Button>
                )}
              </div>
            </div>
            {this.state.openAvisoModal && (
              <AvisoModal
                parentCallback={this.handleCallback}
                openAvisoModal={this.state.openAvisoModal}
                button={this.state.button}
                text={this.state.text}
              ></AvisoModal>
            )}
          </form>
        </div>
      </MuiThemeProvider>
    );
  }
}
