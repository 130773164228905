import React, { Component } from 'react'
import Main from '../template/Main'

export default class Home extends Component {
    render() {
        return (
            <div className="page">
                <Main icon="home" title="Início" subtitle="">
                    <div className="display-4">Bem vindo!</div>
                    <hr />
                    <p className="mb-0">Sistema para administrar usuários do Portal Empresas Vidalink</p>
                </Main>
            </div>
        )
    }
}