import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch'
import PermissoesPad from './PermissoesPad'

export default class Permissoes extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className='row mt-5'>
                <div className='col-sm-12 col-md-12'>
                    <h6 className="titulo">PERMISSÕES</h6>
                    <hr />
                </div>
                
                <div className="card mt-3 largura">
                    <div className="card-header">
                        Relatório Gerenciais
                    </div>
                    <div className="card-body mt-0 pt-2" >
                    <div className='row mt-2'>
                        <div className='col-sm-12 col-md-6'>
                            <div className='col-md-1 comboSwitch col-1--perpadrao'>
                                <Switch checked={this.props.relatorioGerenciais} color="primary"  onChange={this.props.onAtivaInativaRelatorioGerenciais}/>
                            </div>  
                            <div className='col-md-11 pl-2 col-11--perpadrao'>Acesso somente aos Relatórios Gerenciais</div> 
                        </div>
                        </div>
                    </div>
                </div>
                {this.props.relatorioGerenciais ? '' : (
                    Object.keys(this.props.categorias).map((item, i) => ( 
                        this.props.categorias[i].Permissoes.length > 0?
                        this.props.categorias[i].Id !== 12 ? 
                        <PermissoesPad key={i} indice={i} pilares={this.props.pilares} NomeCategoria={this.props.categorias[i].Nome} categorias={this.props.categorias} onUpdateListCat={this.props.onUpdateListCat} atualizaInformacoesLegadas={this.props.atualizaInformacoesLegadas} exibeAvisoModal= {this.props.exibeAvisoModal}/>
                        :
                        this.props.liberacaoEspecial === true ? 
                        <PermissoesPad key={i} indice={i} pilares={this.props.pilares} NomeCategoria={this.props.categorias[i].Nome} categorias={this.props.categorias} onUpdateListCat={this.props.onUpdateListCat} atualizaInformacoesLegadas={this.props.atualizaInformacoesLegadas} exibeAvisoModal= {this.props.exibeAvisoModal}/>
                        : '': ''
                    ))
                )}
            </div>
        )
    }
}