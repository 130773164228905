import './Login.css'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LoginBg from './LoginBg'
import LoginLogo from './LoginLogo'
import LoginTitle from './LoginTitle'
import AuthService from './AuthService'
import Loader from '../template/Loader'
import { Success, Fail } from '../template/Window'


class Esqueci extends Component {

    constructor() {
        super()
        this.handleChange = this.handleChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.Auth = new AuthService()
        this.state = {
            postAction: false
        }
    }

    componentWillMount() {
        if (this.Auth.loggedIn())
            this.props.history.replace('/')
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleFormSubmit(e) {
        this.setState({ postAction: true })
        e.preventDefault();
        this.Auth.recuperarSenha(this.state.username.toUpperCase())
            .then(response => {
                if (response.status !== 200) {
                    Fail(response.statusText)
                } else {
                    Success('Sua nova senha foi enviada para o seu e-mail!')
                }
                this.setState({ postAction: false })
                console.log(JSON.stringify(response))
            })
    }

    habilitarBtn() {
        var botao_enviar = document.getElementById("botaoEnviar")
        if (document.getElementById("user").value.length >= 1) {
            botao_enviar.disabled = false
        } else {
            botao_enviar.disabled = true
        }
    }

    render() {
        if (this.state.postAction) {
            return (
                <div>
                    <LoginBg />
                    <div className="absolute-container">
                        <div className="container">
                            <LoginLogo />
                            <div className="camposform">
                                <LoginTitle />
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <LoginBg />
                <div className="absolute-container">
                    <div className="container">
                        <LoginLogo />
                        <div className="camposform--esqueci">
                            <LoginTitle />
                            <p className="small text-center">
                                Digite seu usuário ou endereço de email.<br />
                                Você receberá uma nova senha após a validação dos dados.
                                </p>
                            <form onSubmit={this.handleFormSubmit}>
                                <div className="camposform__login">
                                    <input type="text" className="form-control" id="user" maxLength="100" name="username" placeholder="Usuário ou E-mail" required onChange={this.handleChange} onKeyPress={this.habilitarBtn} onKeyUp={this.habilitarBtn} />
                                    <i className="fas fa-user"></i>
                                </div>
                                <div className="camposform__esqueci--esqueci">
                                    <Link to="/" className="small"> <i className="fas fa-arrow-left"></i> Voltar para tela de Login</Link>
                                </div>
                                <button type="submit" id="botaoEnviar" className="btn btn-primary" disabled>ENVIAR</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Esqueci
