import React, { Component } from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import './DadosDoCT.css'
import SalvarModal from './ModalSalvar';
import CancelarModal from './ModalCancelar';
import { RequestsClientes } from '../../API/RequestClient'
import Loader from '../template/Loader';
import { Success, Fail } from '../template/Window';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

const theme = createMuiTheme({
    palette: {
        primary: { main: '#038B9B' },
        secondary: { main: '#11cb5f' },
    },
    typography: { useNextVariants: true },
    overrides: {
        MuiButton: {
            contained: {
                color: 'white!important',
                backgroundColor: 'rgba(185, 45, 88, 1)!important',
                borderRadius: '25px'
            },
            containedPrimary: {
                backgroundColor: '#038B9B!important',
                borderRadius: '25px'
            }
        }
    }
})

//PEGA A DATA ATUAL
var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substr(0, 10)

export default class DadosDoCT extends Component {
    constructor(props) {
        super(props)
        this.postForm = this.postForm.bind(this);
        this.postFormCancel = this.postFormCancel.bind(this);

        this.state = {
            openSaveModal: false,
            openCancelModal: false,
            negate: 'Não',
            confirm: 'Sim',
            text: 'Deseja incluir as informações cadastradas?',
            edit: false,
            id: '',
            checkedDashboards: [], 
            dashboards: [],
            ct: 'Código do CT*',
            cts: [],
            segmento: '',
            segmentos: [],
            cluster: '',
            clusters: [],
            dataInicio: '',
            dataFinal: '',
            dataReady: false,
            dataFinalDisabled: false,
            dataInicioAlterada: false,
            dataFinalAlterada: false, 
        }

    }

    componentDidMount() {
        this.carregarDropdowns();
    }

    carregarDropdowns = async () => {
        this.setState({ segmentos: await RequestsClientes.getSegmentos() })
        this.setState({ clusters: await RequestsClientes.getClusters() })
        this.setState({ cts: await RequestsClientes.getCts() })
        this.setState({ dashboards: this.FormatDashboardsArray(await RequestsClientes.getDashboards()) })
        this.setState({ dataReady: true })
    }

    save = async () => {

        let details = {
            "IdIndicePerformance": this.state.id,
            "CT": this.state.ct,
            "IdSegmento": this.state.segmento,
            "Dashboards": this.state.checkedDashboards.map(item => item.value),
            "IdCluster": this.state.cluster,
            "DataInicio": this.state.dataInicio.toString(),
            "DataFim": this.state.dataFinal.toString()
        }

        let result = await RequestsClientes.salvar(details);
    
        if (result === true) {
            this.setState({ openSaveModal: false });
            this.state.edit === true ? Success('Cadastro alterado com sucesso') : Success('Cadastrado realizado com sucesso');

            this.limparCampos();
        }
        else {
            this.state.edit === true ? Fail('Não foi possível alterar o cadastro. ' + result) : Success('Não foi possível realizar o cadastro. ' + result);
        }
    };

    limparCampos = () => {
        this.setState({ id: '' })
        this.setState({ checkedDashboards: [] });
        this.setState({ ct: 'Código do CT*' });
        this.setState({ segmento: '' });
        this.setState({ cluster: '' });
        this.setState({ dataInicio: '' });
        this.setState({ dataFinal: '' });
        this.setState({ edit: false });
        this.setState({ dataInicioAlterada: false});
        this.setState({ dataFinalAlterada: false});
    };

    postForm(e) {
        if(this.ValidaDate()){
            e.preventDefault()
            this.handleChangeModalToEdit();
    
            this.setState({ openSaveModal: true });
        }
    };
    postFormCancel(e) {
        e.preventDefault()
        this.handleChangeModalCancel();

        this.setState({ openCancelModal: true });
    };
    handleCallback = (childData) => {
        this.setState({ openSaveModal: childData })
    };
    handleCallbackResetResults = (childData) => {
        this.handleCallback(childData)
        this.limparCampos()
    };
    handleCallbackCancel = (childData) => {
        this.setState({ openCancelModal: childData })
    };
    handleDashboardChange = evt => {
        this.setState({ checkedDashboards: evt });
    };
    handleCtChange = evt => {
        this.setState({ ct: evt.value });
        this.findCt(evt);
    };
    handleSegmentoChange = evt => {
        this.setState({ segmento: evt.target.value });
    };
    handleClusterChange = evt => {
        this.setState({ cluster: evt.target.value });
    };
    handleDataInicialChange = evt => {
        this.setState({dataInicioAlterada: true})
        this.setState({ dataInicio: evt.target.value });
    }
    handleDataFinalChange = evt => {
        this.setState({dataFinalAlterada: true})
        this.setState({ dataFinal: evt.target.value });
    }
    handleSemTerminoChange = () => {
        this.setState({ dataFinalDisabled: !this.state.dataFinalDisabled })
        this.setState({ dataFinal: '' })
    }
    handleChangeModalToEdit = () => {

        if (this.state.edit === true) {
            this.setState({ text: `Deseja confirmar as modificações realizadas para o ${this.state.ct}?` });
            this.setState({ confirm: 'Confirmar' });
            this.setState({ negate: 'Apagar' });
        } else {
            this.setState({ text: 'Deseja incluir as informações cadastradas?' });
            this.setState({ confirm: 'Sim' });
            this.setState({ negate: 'Não' });
        }
    }
    handleChangeModalCancel = () => {
        this.setState({ text: 'Deseja cancelar as modificações/inclusões realizadas?' });
        this.setState({ confirm: 'Sim' });
        this.setState({ negate: 'Não' });
    }

    findCt = async e => {
        if (e.value !== '' && e.value !== undefined && e.value !== null) {
            this.setState({ dataReady: false });
            let data = await RequestsClientes.infoCt(e.value);
            if (data != null && data !== [] && data !== undefined && data.length !== 0) {
                this.setState({ id: data.IdIndicePerformance })
                this.setState({ ct: data.CT });
                this.setState({ checkedDashboards: this.state.dashboards.filter(dashboard => {return data.Dashboards.includes(dashboard.value) }) });
                this.setState({ segmento: data.IdSegmento });
                this.setState({ cluster: data.IdCluster });
                this.setState({ dataInicio: data.DataInicio });

                if (data.DataFim != null){
                    this.setState({ dataFinal: data.DataFim });
                    this.setState({ dataFinalDisabled: false });
                }
                else {
                    this.setState({ dataFinal: '' });
                    this.setState({ dataFinalDisabled: true });
                }

                this.setState({ edit: true });
            } else {
                this.setState({ id: '' })
                this.setState({ checkedDashboards: [] });
                this.setState({ segmento: '' });
                this.setState({ cluster: '' });
                this.setState({ dataInicio: '' });
                this.setState({ dataFinal: '' });
                this.setState({ dataFinalDisabled: true })

                this.setState({ edit: false });
            }

            this.setState({ dataInicioAlterada: false});
            this.setState({ dataFinalAlterada: false});

            setTimeout(
                () => this.setState({ dataReady: true }),
                200
            );
        }
    }

    FormatDashboardsArray = (array) => {
        if (Array.isArray(array) && array.length > 0) {
          const novoArrayFormatado = array.map(item => ({
            value: item.IdDashboard,
            label: item.NomeDashboard
          }));
      
          return novoArrayFormatado;
        } else {
          return [];
        }
    }

    ValidaDate = () => {
        var dataInicioInvalida = this.state.dataInicio.toString() < date && this.state.dataInicioAlterada;
        var dataFinalInvalida = this.state.dataFinal.toString() < date && !this.state.dataFinalDisabled && this.state.dataFinalAlterada;

        if(dataInicioInvalida && dataFinalInvalida){
            this.state.edit = Fail('Não foi possível concluír o cadastro. Data Início e Data Fim inválidas');
            return false; 
        }

        if(dataInicioInvalida){
            this.state.edit = Fail('Não foi possível concluír o cadastro. Data Início inválida');
            return false; 
        }

        if (dataFinalInvalida){
            this.state.edit = Fail('Não foi possível concluír o cadastro. Data Fim inválida');
            return false; 
        }

        return true; 
    }

    render() {
        const { checkedDashboards, ct, segmento, cluster, dataInicio, dataReady, dataFinal, dataFinalDisabled } = this.state;

        const validaButton = checkedDashboards.length > 0  && ct !== 'Código do CT*' && segmento !== '' && cluster !==
            '' && dataInicio !== '' && (dataFinal !== '' && !dataFinalDisabled || dataFinal === '' && dataFinalDisabled ) ? true : false
        
        

        return (

            <MuiThemeProvider theme={theme}>

                {(!dataReady) ? <div id='load'><Loader /></div> : <></>}
                <div className='col-sm-12 col-md-12' style={{ display: dataReady ? '' : 'none' }}>

                    <form name='cadastro' onChange={this.validaInput} >
                        <div className='row mt-3'>
                            <div className='col-sm-12 col-md-12'>
                                <h6 className="titulo">DADOS DO CT</h6>
                                <hr />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                                <MultiSelect 
                                        overrideStrings={{
                                            selectSomeItems: "Dashboard*",
                                            allItemsAreSelected: "Todos os itens estão selecionados",
                                            selectAll: "Selecionar todos",
                                            search: "Buscar",
                                        }}
                                        options={this.state.dashboards}
                                        value={checkedDashboards}
                                        onChange={this.handleDashboardChange}
                                        hasSelectAll={false}
                                        disableSearch={true}
                                    />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                                <Select key={`ct_${this.state.ct}`} defaultValue={{ value: this.state.ct, label: this.state.ct }} name='codigoCT' id='codigoCT' minLength='2' type='text' onChange={this.handleCtChange} required options={this.state.cts} />
                            </div>
                            <div className='col-sm-12 col-md-3'>
                                <select name='segmento' value={this.state.segmento} id='segmento' className='form-control' required onChange={this.handleSegmentoChange}>
                                    <option disabled value=''>Segmento*</option>
                                    {
                                        Object.keys(this.state.segmentos.sort(function (a, b) { return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0; })).map((item, i) => (
                                            <option value={this.state.segmentos[item].IdSegmento} key={i}>{this.state.segmentos[item].NomeSegmento}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='col-sm-12 col-md-3'>
                                <select name='cluster' value={this.state.cluster} id='cluster' className='form-control' required onChange={this.handleClusterChange}>
                                    <option disabled value=''>Cluster*</option>
                                    {
                                        Object.keys(this.state.clusters.sort(function (a, b) { return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0; })).map((item, i) => (
                                            <option value={this.state.clusters[item].IdCluster} key={i}>{this.state.clusters[item].NomeCluster}</option>
                                        ))
                                    }
                                </select>
                            </div>

                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-12 col-md-6 mt-3'>
                                <div className="card">
                                    <div className="card-header">
                                        Inativação de login
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text small">As datas abaixo refletem o início e o término do acesso ao dash</p>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-6 small'>
                                                Início*<br />
                                                <div className="input-group input-group-sm mb-3">
                                                    <input name='datainicio' id='datainicio' value={this.state.dataInicio} min={date} className='form-control ' type='date' required onChange={this.handleDataInicialChange} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar text-info"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-6 small'>
                                                Fim*<br />
                                                <div className="input-group input-group-sm mb-3">
                                                    <input name='datafim' id='datafim' value={this.state.dataFinal} min={date} className='form-control' type='date' required onChange={this.handleDataFinalChange} disabled = {(this.state.dataFinalDisabled)? "disabled" : ""} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar text-info"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-2 checkbox form-control'>
                                <input class="form-check-input" type="checkbox" checked={this.state.dataFinalDisabled} value="" id="flexCheckDefault" onClick={this.handleSemTerminoChange}/>
                                    <span class="form-check-label" for="flexCheckDefault">
                                        Sem término
                                    </span>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-md-6 text-center">
                                <Button disabled={!validaButton} type='button' id="save-btn" variant="contained" size="large" color="primary" onClick={this.postForm}  >
                                    <span style={{ fontSize: 20, marginRight: 0 }}><i className="fas fa-save"></i></span>Salvar informações
                                </Button>
                            </div>
                            <div className="col-md-6 text-center">
                                <Button type='button' id="cancel-btn" variant="contained" size="large" color="default" onClick={this.postFormCancel}  >
                                    <span style={{ fontSize: 20, marginRight: 0 }}><i className="fas fa-times-circle"></i></span>Cancelar
                                </Button>
                            </div>
                        </div>
                        {this.state.openSaveModal && <SalvarModal save={this.save} parentCallbackAndReset={this.handleCallbackResetResults} parentCallback={this.handleCallback} openSaveModal={this.state.openSaveModal} negate={this.state.negate} confirm={this.state.confirm} text={this.state.text} edit={this.state.edit}></SalvarModal>}
                        {this.state.openCancelModal && <CancelarModal clean={this.limparCampos} parentCallback={this.handleCallbackCancel} openCancelModal={this.state.openCancelModal} negate={this.state.negate} confirm={this.state.confirm} text={this.state.text} ></CancelarModal>}
                    </form>
                </div>
            </MuiThemeProvider>
        )
    }

}