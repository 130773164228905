import React, { Component } from "react";
import Clients from "./Clients";
import "./Customers.css";

export default class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: this.props.clients,
      customers: [],
      desabilitarCombo: false,
      modalClientsOpen: false,
      todosClientsMarcados: false,
      itemClientSelecionado: false,
    };

    this.getCustomer = this.getCustomer.bind(this);
    this.actionSelect = this.actionSelect.bind(this);
    this.renderItemCustomerSelected =
      this.renderItemCustomerSelected.bind(this);
    this.onCloseCustomerSelected = this.onCloseCustomerSelected.bind(this);
    this.retornaTotalAtivoClients = this.retornaTotalAtivoClients.bind(this);
    this.onClientModal = this.onClientModal.bind(this);
    this.marcarTodosClients = this.marcarTodosClients.bind(this);
    this.verificaTodosClientsAtivos =
      this.verificaTodosClientsAtivos.bind(this);
    this.setItemClientSelecionado = this.setItemClientSelecionado.bind(this);
  }

  componentDidMount() {
    this.getCustomer();
  }

  getCustomer() {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/customer?somenteAtivos=true&apenasCustomer=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) =>
        this.setState({
          ...this.state,
          customers: this.renomearChaves(data),
        })
      );
  }

  renomearChaves(e) {
    if (Array.isArray(e)) {
      for (var i = 0; i <= e.length; i++) {
        this.renomearChaves(e[i]);
      }
    } else {
      for (var key in e) {
        if (typeof e[key] === typeof {}) {
          this.renomearChaves(e[key]);
        }

        if (key === "customer") {
          e["Ct"] = e[key];
          delete e[key];
        } else if (key.charAt(0).toUpperCase() + key.substring(1) !== key) {
          e[key.charAt(0).toUpperCase() + key.substring(1)] = e[key];
          delete e[key];
        }
      }
    }
    return e;
  }

  setItemClientSelecionado(status) {
    this.setState({ itemClientSelecionado: status });
  }

  marcarTodosClients() {
    let itemSelecionado = this.state.itemClientSelecionado;
    let todosClientMarcados = !this.state.todosClientsMarcados;
    let lstClient = [...this.props.clients];
    for (let i = 0; i < lstClient.length; i++) {
      lstClient[i].ativo = todosClientMarcados;

      if (todosClientMarcados)
        for (let a = 0; a < lstClient[i].groups.length; a++)
          lstClient[i].groups[a].ativo = true;
    }

    if (!todosClientMarcados) itemSelecionado = false;

    this.setState({
      clients: lstClient,
      todosClientsMarcados: todosClientMarcados,
      itemClientSelecionado: itemSelecionado,
    });
  }

  actionSelect(event) {
    this.setState({
      desabilitarCombo: true,
    });

    this.props.onUpdateCustomerSelected(event.target.value);
    this.props.getClient(event.target.value);
    this.props.getLiberacaoEspecial(event.target.value);
  }

  onClientModal(open) {
    this.verificaTodosClientsAtivos();

    if (this.retornaTotalAtivoClients(this.props.clients) === 0)
      this.setState({ modalClientsOpen: open, itemClientSelecionado: false });
    else this.setState({ modalClientsOpen: open, itemClientSelecionado: true });
  }

  onCloseCustomerSelected() {
    this.setState({
      desabilitarCombo: false,
      modalClientsOpen: false,
    });

    this.props.onUpdateCustomerSelected("");
    this.props.onUpdateClient([]);
    this.props.getLiberacaoEspecial("-");
  }

  retornaTotalAtivoClients(item) {
    let totalAtivoClients = item?.length;

    return totalAtivoClients;
  }

  verificaTodosClientsAtivos() {
    let todosSeleciodados = true;
    for (let i = 0; i < this.props.clients.length; i++) {
      if (!this.props.clients[i].ativo) todosSeleciodados = false;
    }
    this.setState({ todosClientsMarcados: todosSeleciodados });
  }

  renderItemCustomerSelected() {
    if (
      this.props.idCustomerSelected === "" ||
      this.state.customers.length === 0 ||
      this.props.idCustomerSelected === undefined
    )
      return "";

    return (
      <div className="customer-vinculado">
        <h5>
          {
            this.state.customers.find(
              (e) => e.Id === this.props.idCustomerSelected
            ).Nome
          }
        </h5>
        {this.retornaTotalAtivoClients(this.props.activeClients) ===
        this.props.clients.length ? (
          <span>Todos os Clients selecionados para este customer</span>
        ) : this.retornaTotalAtivoClients(this.props.activeClients) === 0 ? (
          <span id="customer-desc">Nenhum Client selecionado</span>
        ) : (
          <span id="customer-desc">
            {this.retornaTotalAtivoClients(this.props.activeClients)} Clients
            selecionados de {this.props.clients.length} para este Customer
          </span>
        )}
        {!this.props.edit ? (
          <div>
            <span
              id="customer-vinculado__bnt-edit"
              className="customer-vinculado__bnt-edit"
              onClick={() => this.onClientModal(this)}
            >
              <i className="fas fa-pen"></i>
            </span>
            <span
              className="customer-vinculado__bnt-close"
              onClick={this.onCloseCustomerSelected}
            >
              <i className="fas fa-times"></i>
            </span>
          </div>
        ) : (
          <span
            id="customer-vinculado__bnt-edit"
            className="customer-vinculado__bnt-edit tela-edit-true"
            onClick={() => this.onClientModal(this)}
          >
            <i className="fas fa-pen"></i>
          </span>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="col-sm-12 col-md-6">
        <div className="card">
          <div className="card-header">Vincular customer</div>
          <div id="customer" className="card-body pb-4">
            <select
              id="select-customer"
              name="select-customer"
              className="form-control mb-3"
              placeholder="Pesquisar customer"
              value={this.props.idCustomerSelected}
              onChange={this.actionSelect}
              disabled={
                this.props.edit ? this.props.edit : this.state.desabilitarCombo
              }
            >
              <option value="">Pesquisar customer</option>
              {Object.keys(this.state.customers).map((item, i) => (
                <option value={this.state.customers[item].Id} key={i}>
                  {this.state.customers[item].Id +
                    " - " +
                    this.state.customers[item].Nome}
                </option>
              ))}
            </select>
            {this.renderItemCustomerSelected()}
          </div>
        </div>
        <Clients
          onUpdateClient={this.props.onUpdateClient}
          edit={this.props.edit}
          onClientModal={this.onClientModal}
          clients={this.props.clients}
          activeClients={this.props.activeClients}
          modalClientsOpen={this.state.modalClientsOpen}
          marcarTodosClients={this.marcarTodosClients}
          todosClientsMarcados={this.state.todosClientsMarcados}
          verificaTodosClientsAtivos={this.verificaTodosClientsAtivos}
          itemClientSelecionado={this.state.itemClientSelecionado}
          setItemClientSelecionado={this.setItemClientSelecionado}
          setActiveClients={this.props.setActiveClients}
        />
      </div>
    );
  }
}
