import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import Button from '@material-ui/core/Button'

import './Groups.css'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'

export default class Groups extends Component {

    constructor(props) {
        super(props)

        this.state = {
            
        }

        this.renderGroups = this.renderGroups.bind(this)
        this.setStatusGroup = this.setStatusGroup.bind(this)
    }

    setStatusGroup(indice, ativo) {
        //CONDICIONAL PARA SELECIONAR APENAS 1 CLIENT GROUP OU TODOS
        if(this.props.itemGroupSelecionado && ativo)
            return

        this.props.setItemGroupSelecionado(ativo)
        let lstClient = [...this.props.clients]
        lstClient[this.props.idClientSelected].groups[indice].ativo = ativo
        this.props.verificaTodosGroupsMarcados(this.props.idClientSelected)
        this.props.onUpdateClient(lstClient)
    }

    renderGroups() {
        return (
            this.props.clients[this.props.idClientSelected] !== undefined ? (           
            this.props.clients[this.props.idClientSelected].Groups.map((item, i) => (
                item.ativo ? (
                    <div key={item.Id} className="clients-vinculado groups-vinculado clients-selecionado">
                        <h5>{item.Nome}</h5>
                        {/* CONDICIONAL PARA TRAVAR A SELEÇÃO DE GROUPS QUANDO TODOS ESTÃO SELECIONADOS */}
                        {!this.props.todosGroupsMarcados ? (
                        <span className="group-vinculado__bnt-close group-vinculado__bnt-activate" onClick={() => this.setStatusGroup(i, false)} >
                            <i className="fas fa-check fas-groups"></i>
                        </span>
                        ): ''}
                    </div>
                ) : (
                        <div key={item.Id} className="clients-vinculado groups-vinculado">
                            <h5>{item.Nome}</h5>
                            {/* CONDICIONAL PARA SELECIONAR APENAS 1 CLIENT GROUP OU TODOS */}
                            {this.props.itemGroupSelecionado ? '' :(
                            <span className="group-vinculado__bnt-close group-vinculado__bnt-close" onClick={() => this.setStatusGroup(i, true)} >
                                <i className="fas"></i>
                            </span>
                            )}
                        </div>
                    )
            )
            )) : ''
        )
    }

    render() {
        return (
            <Modal open={this.props.modalGroupOpen ? true : false} onClose={this.props.onCloseModalGroup} >
                <h2>Groups selecionados</h2>
                {this.props.todosGroupsMarcados ? (
                        <div className="groups-todos">
                        <span className="clients-vinculado__bnt-close group-vinculado__bnt-close-todos clients-vinculado__bnt-activate" onClick={this.props.marcaTodosGroupsAtivo} >
                            <i className="fas todos fa-check"></i>
                        </span>
                            <h6>Selecionar todos os Groups</h6>
                        </div>
                    ):(
                        <div className="groups-todos">
                        <span className="clients-vinculado__bnt-close group-vinculado__bnt-close-todos" onClick={this.props.marcaTodosGroupsAtivo} >
                            <i className="fas todos fa-check"></i>
                        </span>
                            <h6>Selecionar todos os Groups</h6>
                    </div>
                    )}
                <div className="corpo_modal">
                    <div className="corpo_clients">
                        {(!this.props.modalGroupOpen && this.props.idClientSelected !== '') ? '' : (this.renderGroups())}
                    </div>
                    <div className="btn-corpo">
                        <Button className="btn-confirmar" variant="contained" size="large" color="primary" type="submit" onClick={this.props.onCloseModalGroup}>Voltar</Button>
                        {/* <Button className="btn-cancelar" variant="contained" size="large" onClick={this.props.onCloseModalGroup}>Cancelar</Button> */}
                    </div>
                </div>
            </Modal>
        )
    }
}