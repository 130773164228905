import Button from '@material-ui/core/Button'
import Modal from 'react-responsive-modal'
import React, { Component } from 'react'

export default class AlterarSenha extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalSalvarOpen: props.openSaveModal
        }


        this.onCloseSalvarModal = this.onCloseSalvarModal.bind(this)
        this.salvar = this.salvar.bind(this)
        this.onClose = this.onClose.bind(this)
    }

    onSalvarModal() {
        this.setState({ modalSalvarOpen: true })
    }
    salvar() {
        this.props.save();
    }
    onClose() {
        this.setState({ modalSalvarOpen: false })
        this.props.parentCallback(false);
    }
    onCloseSalvarModal() {
        this.setState({ modalSalvarOpen: false })
        console.log('APAGAR')
        console.log(this.props.edit)
        if (this.props.edit === false) {
            this.props.parentCallback(false);
        }
        else {
            this.props.parentCallbackAndReset(false);
        }
    }


    render() {
        return (
            <Modal open={this.state.modalSalvarOpen} onClose={this.onClose}>
                <div className="content text-center">
                    <div className="col-sm-12">
                        <span style={{ fontSize: 16 }}>{this.props.text}</span>
                    </div>
                    <div className="row col-sm-12 pt-4">
                        <div className="col-sm-6">
                            <Button variant="contained" size="small" color="primary" style={{ color: '#ffffff', marginLeft: 15, width: '100%' }} type="submmit" onClick={this.salvar}>
                                <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}></span>{this.props.confirm}
                            </Button>
                        </div>
                        <div className="col-sm-6">
                            <Button variant="contained" size="small" color="default" style={{ color: '#ffffff', marginLeft: 15, width: '100%' }} type="submmit" onClick={this.onCloseSalvarModal}>
                                <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}></span>{this.props.negate}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

}