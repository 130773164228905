import { Api } from "./ApiConfig";

const getCts = async () => {
    let resApi
    let config = {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('id_token')}`
        }
    }

    await Api().get(`api/v1/indicePerformance/listarCTs`, config)
        .then(res => {
            const options = []

            for (var i = 0; i < res.data.length; i++) {
                options.push({ value: res.data[i].Id, label: res.data[i].Id })
            }

            resApi = options;
        })
        .catch(() => console.log('Erro ao Buscar Lista de CTs'))

    return resApi
}

const infoCt = async (ct) => {
    let resApi
    let config = {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('id_token')}`
        }
    }

    await Api().get(`api/v1/indicePerformance/buscarCT/${ct}`, config)
        .then(res => {
            resApi = res.data


        })
        .catch(() => console.log('Erro ao Buscar CT'))

    return resApi
}

const getSegmentos = async () => {
    let resApi
    let config = {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('id_token')}`
        }
    }

    await Api().get(`api/v1/indicePerformance/segmentos`, config)
        .then(res => {
            resApi = renomearChaves(res.data)
        })
        .catch(() => console.log('Erro ao Buscar Segmentos'))

    return resApi

}

const getClusters = async () => {
    let resApi
    let config = {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('id_token')}`
        }
    }

    await Api().get(`api/v1/indicePerformance/clusters`, config)
        .then(res => {
            resApi = renomearChaves(res.data)
        })
        .catch(() => console.log('Erro ao Buscar Clusters'))

    return resApi

}

const getDashboards = async () => {
    let resApi
    let config = {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('id_token')}`
        }
    }

    await Api().get(`api/v1/indicePerformance/dashboards`, config)
        .then(res => {
            resApi = renomearChaves(res.data)
        })
        .catch(() => console.log('Erro ao Buscar Dashboards'))

    return resApi

}

function renomearChaves(e) {
    if (Array.isArray(e)) {
        for (var i = 0; i <= e.length; i++) {
            renomearChaves(e[i])
        }
    } else {
        for (var key in e) {
            if (typeof e[key] === typeof {}) {
                renomearChaves(e[key])
            }

            if (key === "customer") {
                e["Ct"] = e[key]
                delete e[key]
            }
            else if (key.charAt(0).toUpperCase() + key.substring(1) !== key) {
                e[key.charAt(0).toUpperCase() + key.substring(1)] = e[key]
                delete e[key]
            }
        }
    }

    return e
}

const salvar = async (details) => {
    let resApi;

    await fetch(`${process.env.REACT_APP_API_URL}/api/v1/indicePerformance/salvar`, {

        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${localStorage.getItem('id_token')}`
        },
        method: 'POST',
        body: JSON.stringify(details)
    })
        .then(response => {
            if (response.status !== 200 && response.status !== 204) {
                resApi = response.statusText;
            } else {
                resApi = true;
            }
        })


    return resApi
}

const getChatBotPillarApi = async () => {
    let resApi, token, pilares =[]

    await fetch(`${process.env.REACT_APP_HACHIMON_URL}/token`, {
        headers: {
            'Content-Type': 'application/json; text/plain'
        },
        method: 'POST',
        body: JSON.stringify({ service_hash: "0a32427a-aaa6-4c3f-a22f-e1f9e5ad8916" })
    })
        .then(r => r.json())
        .then(j => {
            token = j.access_token;
        })

    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    await Api().get(`${process.env.REACT_APP_HACHIMON_URL}/v1/chatbot/pillar`, config)
        .then(res => {
            resApi = renomearChaves(res.data)
            pilares = resApi.Data            
        })
        .catch(() => console.log('Erro ao Buscar Pilares'))
    return pilares
}

const verifyIdPilar = async (idPilar, pilares) => {
    let retorno = null

    await pilares.forEach(item => {
        if (item.PillarId === idPilar) {
            retorno = item.Name;
        }

    });

    return retorno;
}

export const RequestsClientes = {
    getCts,
    infoCt,
    getSegmentos,
    getClusters,
    getDashboards,
    salvar,
    verifyIdPilar,
    getChatBotPillarApi
}