import Button from '@material-ui/core/Button'
import Modal from 'react-responsive-modal'
import React, { Component } from 'react'

export default class ModalCancelar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalCancelarOpen: props.openCancelModal
        }


        this.onCloseCancelarModal = this.onCloseCancelarModal.bind(this)
        this.limparCampos = this.limparCampos.bind(this)
    }

    onCancelarModal() {
        this.setState({ modalCancelarOpen: true })
    }
    limparCampos() {
        this.props.clean();
        this.onCloseCancelarModal();
    }
    onCloseCancelarModal() {
        this.setState({ modalCancelarOpen: false })
        this.props.parentCallback(false);
    }


    render() {
        return (
            <Modal open={this.state.modalCancelarOpen} onClose={this.onCloseCancelarModal}>
                <div className="content text-center">
                    <div className="col-sm-12">
                        <span style={{ fontSize: 16 }}>{this.props.text}</span>
                    </div>
                    <div className="row col-sm-12 pt-4">
                        <div className="col-sm-6">
                            <Button variant="contained" size="small" color="primary" style={{ color: '#ffffff', marginLeft: 15, width: '100%' }} type="submmit" onClick={this.limparCampos}>
                                <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}></span>{this.props.confirm}
                            </Button>
                        </div>
                        <div className="col-sm-6">
                            <Button variant="contained" size="small" color="default" style={{ color: '#ffffff', marginLeft: 15, width: '100%' }} type="submmit" onClick={this.onCloseCancelarModal}>
                                <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}></span>{this.props.negate}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

}