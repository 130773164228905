export default class AuthService {
    constructor(domain) {
        
        this.domain = domain || process.env.REACT_APP_API_URL
        console.log("CONSTRUTOR ", this.domain)
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    encodePayload(payload) {
        return JSON.stringify(payload).replace(/[\u007F-\uFFFF]/g, function (c) {
                return "\\u" + ("0000" + c.charCodeAt(0).toString(16)).substr(-4);
            });
    }

    recuperarSenha(email) {
        let details = {
            email
        };

        let formBody = [];
        for (var property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        return this.fetch(`${this.domain}/api/v1/usuario/EsqueciMinhaSenha`, {
            method: 'POST',
            body: formBody
        }).then(res => {
            return Promise.resolve(res)
        })
    }

    login(username, password) {
        let details = {
            username,
            password,
            'grant_type': 'password'
        };

        let formBody = [];
        for (var property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return this.fetch(`${this.domain}/token`, {
            method: 'POST',
            body: formBody
        })
    }

    fetch(url, options) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        return fetch(url, {
            headers,
            ...options
        })
    }

    getToken() {
        return localStorage.getItem('id_token')
    }

    setToken(token) {
        localStorage.setItem('id_token', token.access_token)
        var t = new Date()
        localStorage.setItem('token_expired', t.setSeconds(t.getSeconds() + token.expires_in))
    }

    loggedIn() {
        return this.getToken() != null;
    }

    logout() {
        localStorage.removeItem('id_token');
        localStorage.removeItem('username');
    }

    getProfile() {
        return this.getToken() != null;
    }   
}