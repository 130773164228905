import './Login.css'
import React, { Component } from 'react'

import LoginBg from './LoginBg'
import LoginLogo from './LoginLogo'
import LoginTitle from './LoginTitle'
import AuthService from './AuthService'
import md5 from 'md5'
import { Fail } from '../template/Window';
import LoginForm from './LoginForm'
import Loader from '../template/Loader'

class PageLogin extends Component {

    constructor() {
        super()
        this.handleChange = this.handleChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.Auth = new AuthService()
        this.state = {
            postAction: false
        }
    }

    componentDidMount() {
        if (this.Auth.loggedIn())
            this.props.history.push('/')
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleFormSubmit(e) {
        this.setState({ postAction: true })
        e.preventDefault()

        this.Auth.login(this.state.username.toUpperCase(), md5(this.state.password.toUpperCase()))
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('username', this.state.username)
                    res.json()
                        .then(token => {
                        this.Auth.setToken(token)
                        this.props.history.push('/home')
                        })
                }
                else{
                    Fail('Os dados de acesso parecem estar incorretos, verifique seus dados e tente novamente.')
                }
                this.setState({ postAction: false })
            })
            .catch(err => {
                Fail('Os dados de acesso parecem estar incorretos, verifique seus dados e tente novamente.')
                this.setState({ postAction: false }) 
            })
    }

    render() {

        if (this.state.postAction) {
            return (
                <div>
                    <LoginBg />
                    <div className="absolute-container">
                        <div className="container">
                            <LoginLogo />
                            <div className="camposform"> 
                                <LoginTitle />
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <LoginBg />
                    <div className="absolute-container">
                        <div className="container">
                            <LoginLogo />
                            <div className="camposform">
                                <LoginTitle />
                                <LoginForm handleFormSubmit={this.handleFormSubmit} handleChange={this.handleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default PageLogin
