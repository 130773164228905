import React, { Component } from 'react'

export default class NomeUsuario extends Component {
    render(){
        return(
            <h6 data-tip={this.props.tooltip}>
                <i className="fa fa-cogs ml-3 mr-1"></i>
                <strong>{this.props.nome}</strong>
            </h6>
        )
    }
}