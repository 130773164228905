import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './App.css'

import React, { Component } from 'react'
import AuthService from './../components/login/AuthService'
import withAuth from './../components/login/withAuth'
import { BrowserRouter } from 'react-router-dom'
import Alert from 'react-s-alert'

import Routes from './Routes'
import Logo from '../components/template/Logo'
import Nav from '../components/template/Nav'
import Footer from '../components/template/Footer'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
const Auth = new AuthService()
let killInterval

class App extends Component {
    
    constructor(props) {
        super(props)
        this.state = {

        }
        this.logoutAuto = this.logoutAuto.bind(this)
    }

    componentDidMount(){
        this.logoutAuto()
    }

    render() {
        return (
            <BrowserRouter>
                <div className="app">
                    <Logo />
                    <Nav />
                    <Routes />
                    <Footer/>
                    <Alert stack={{limit: 3}} />
                </div>
            </BrowserRouter>
        )
    }

    handleLogout(){
        Auth.logout()
        this.props.history.replace('/login')
        clearInterval(killInterval) 
    }

    logoutAuto() {
       killInterval =  setInterval(() => {
            if(localStorage.getItem('id_token') == null || localStorage.getItem('token_expired') < Date.now()) { 
                this.handleLogout()
            } 
        }, 2000)
    }
}

export default withAuth(App)