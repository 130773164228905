import React, { Component } from 'react';
import Main from '../template/Main'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import MUIDataTable from "mui-datatables";
import CustomToolbar from "./CustomToolbar";
import Button from '@material-ui/core/Button'
import CustomToolbarSelect from "./CustomToolbarSelect";
import dateFormat from "dateformat"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import './Usuarios.css'
import Loader from '../template/Loader'
import Modal from 'react-responsive-modal'
import { Success, Fail } from '../template/Window'
import { Link } from 'react-router-dom'

const headerProps = {
    icon: 'users',
    title: 'Usuários',
    subtitle: 'Cadastros realizados até o momento'
}

//MUDAR CORES PRIMARIAS E SECUNDÝRIAS DO MATERIAL.IO (BOTÕES E CHECKBOXES)
const theme = createMuiTheme({
    palette: {
        primary: { main: '#038B9B' }, // Mudar cor primária para cheboxes e botões do material.io.
        secondary: { main: 'rgba(96, 41, 108, 1)' }, // Cor secundária, verde próximo ao verde vidalink.
    },
    typography: { useNextVariants: true },
    overrides: {
        MUIDataTableHeadCell: {
            fixedHeader: {
                //background: 'linear-gradient(top, rgba(0, 112, 173, 1), rgba(102, 187, 144, 1))',
                backgroundColor: 'rgba(96, 41, 108, 1)',
                color: '#ffffff',
                fontSize: 12
            }
        },
        MUIDataTableSelectCell: {
            headerCell: {
                //background: 'linear-gradient(top, rgba(0, 112, 173, 1), rgba(102, 187, 144, 1))',
                backgroundColor: 'rgba(96, 41, 108, 1)',
                color: '#ffffff'
            },
            checked: {
                color: '#038B9B!important'
            }
        },
        MuiTable: {
            root: {
                marginTop: 5
            }
        },
        MUIDataTableSearch: {
            main: {
                opacity: '1!important'
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: 'rgba(96, 41, 108, 1)!important'
            }
        },

        MuiButton: {
            contained: {
                color: 'white!important',
                backgroundColor: 'rgba(185, 45, 88, 1)!important',
                borderRadius: '25px'
            },
            containedPrimary: {
                backgroundColor: '#038B9B!important',
                borderRadius: '25px'
            }
        }
    }
})

class Usuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lista: [],
            dataReady: false,
            openModal: false,
            usuario: '',
            page: 0,
            rowsPerPage: 20
        }


        this.verificaNull = this.verificaNull.bind(this)
        this.verificaInativacao = this.verificaInativacao.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        this.buscaAtivada = this.buscaAtivada.bind(this)
    }

    componentDidMount() {
        this.getListaUser()
        this.verificaNull()
        this.verificaInativacao()
        this.buscaAtivada()
    }

    buscaAtivada() {
        let timer = setInterval(() => {
            let barradeBusca = document.getElementsByTagName("div")[0]
            let btnBuscar = barradeBusca.getElementsByTagName('button')[0]

            if (btnBuscar && barradeBusca) {
                btnBuscar.dispatchEvent(new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    clientX: 20,
                }))
                clearInterval(timer)
            }
        }, 1000)
    }

    //MOSTRAR TELA MODAL
    onOpenModal = (value) => {
        this.setState({ openModal: true, usuario: value[2], ativar: value[0], idUser: value[1] })
    }

    //FECHAR TELA MODAL
    onCloseModal = () => {
        this.setState({ openModal: false })
    }

    AtivaOuInativaUsuario(ativar, idUser) {
        let url;
        if (ativar)
            url = `${process.env.REACT_APP_API_URL}/api/v1/usuario/Inativar/`+idUser
        else
            url = `${process.env.REACT_APP_API_URL}/api/v1/usuario/Ativar/`+idUser
            
        fetch(url, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`
            },
            method: 'PUT'
        })
            .then(response => {
                if (response.status !== 200) {
                    Fail(response.statusText)
                } else {
                    Success(ativar ? 'Usuario Inativado com sucesso!' : 'Usuario Ativado com sucesso!')

                    this.componentDidMount()
                }

                this.setState({ postAction: false })
                this.onCloseModal()
            })
    }

    getListaUser() {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/usuario/Todos`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`
            },
        })
            .then(resp => resp.json())
            .then(data => {
                this.renomearChaves(data)
                this.setState({
                ...this.state,
                lista: data,
                dataReady: true,
            })}).catch(err => {
                this.setState({
                    dataReady: true
                })
            })
    }

    verificaNull = (e) => {
        return e === undefined ? "-" : e
    }

    verificaInativacao = (i) => {
        return i === "01/01/9999" ? "-" : i
    }

    renomearChaves(e){
        if (Array.isArray(e)){
            for(var i = 0; i <= e.length; i++){
                this.renomearChaves(e[i])
            }
        }else{
            for(var key in e){
                if(typeof e[key] === typeof {}){
                    this.renomearChaves(e[key])
                }

                if(key === "customer"){
                    e["Ct"] = e[key]
                    delete e[key]
                }
                else if(key.charAt(0).toUpperCase()+ key.substring(1) !== key){
                    e[key.charAt(0).toUpperCase()+ key.substring(1)] = e[key]
                    delete e[key]
                }

                
            }
        }
        return e
    }

    getClientListWrapper = (page, rowsPerPage) => {
        this.getClientList().then(data => {
            this.renomearChaves(data)
            this.setState({
                page: page,
                rowsPerPage: rowsPerPage,
                data
            });
        });
    };

    render() {
        const columns = [
            {
                name: "Usuário",
                options: {
                    filter: false,
                    customBodyRender: (value) => {
                        let link = "cadastrar/" + value[1]
                        return (
                            localStorage.getItem('username').substring(0, 2) === 'AD' ? (
                                value[0].substring(0, 2) === 'SA' ? (
                                   <strong>{value[0]}</strong>
                                ) : (
                                        <Link to={link}><strong>{value[0]}</strong></Link>
                                    )
                                ) : (
                                        <Link to={link}><strong>{value[0]}</strong></Link>
                                    )

                        )
                    },
                }
            },
            {
                name: "Perfil",
                options: {
                    filter: true
                }
            },
            {
                name: "E-mail",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Customer",
                options: {
                    filter: false
                }
            },
            {
                name: "Criação",
                options: {
                    filter: true,
                    sortDirection: 'desc',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            dateFormat(value, "dd/mm/yyyy")
                        )
                    },
                    // customFilterValueRender: (value) => {
                    //     return <b>{new Date(value).toISOString()[0]}</b>;
                    // },
                    // customFilterFn: (value, columnValue) => {
                    //     return new Date(value[0]).getTime() >= new Date(columnValue).getTime();
                    // },
                    // customFilterRender: (value, onChange, className) => {
                    //     return (
                    //         <TextField
                    //           id="Criação"
                    //           key="Criação"
                    //           label="Criação"
                    //           className={className}
                    //           type="datetime"
                    //           value={value[0] || "1995-05-01"}
                    //           InputLabelProps={{
                    //             shrink: true,
                    //           }}
                    //           /* DO NOT FORGET TO CALL THE `onChange` METHOD! */
                    //           /* TYPE MULTISELECT MUST RETURN AN ARRAY!! */
                    //           onChange={(e) => onChange([e.target.value])}
                    //         />
                    //     );
                    // },

                }
            },
            {
                name: "Inativação",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            this.verificaInativacao(dateFormat(value, "dd/mm/yyyy"))
                        )
                    }
                }
            },
            {
                name: "Status",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            localStorage.getItem('username').substring(0, 2) === 'AD' ? (

                                value[2].substring(0, 2) !== 'SA' && value[2].substring(0, 2) !== 'AD' ? (
                                    <Button
                                        variant="contained" size="small" color={value[0] ? 'primary' : 'default'} type="submit"
                                        onClick={() => {
                                            this.onOpenModal(value);
                                        }}>{value[0] ? "Ativo" : "Inativo"}</Button>
                                ) : ('')
                            ) : (
                                value[2].substring(0, 2) !== 'SA' ?(
                                    <Button
                                        variant="contained" size="small" color={value[0] ? 'primary' : 'default'} type="submit"
                                        onClick={() => {
                                            this.onOpenModal(value);
                                        }}>{value[0] ? "Ativo" : "Inativo"}</Button>
                                ) : ('')
                                )

                        );
                    }
                }
            },
            {
                name: "Nome",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Nome CT",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            value ? 'Ativo' : 'Inativo'
                        );
                    }
                }
            }
        ];
        
        const ListaArray = []
        Object.keys(this.state.lista).map((item) => (
            ListaArray.push(
                [[this.state.lista[item].Login, this.state.lista[item].Id], this.verificaNull(this.state.lista[item].TipoUsuario.Nome), this.verificaNull(this.state.lista[item].Email), this.verificaNull((this.state.lista[item].Ct !== undefined) ? (this.state.lista[item].Ct.Id) : ('-')), this.verificaNull(this.state.lista[item].DataCriacao), this.verificaNull(this.state.lista[item].DataInativacao), [this.verificaNull(this.state.lista[item].Ativo), this.state.lista[item].Id, this.state.lista[item].Login], this.verificaNull(this.state.lista[item].Nome), this.verificaNull((this.state.lista[item].Ct !== undefined) ? (this.state.lista[item].Ct.Nome) : ('-')), this.verificaNull(this.state.lista[item].Ativo)]
            )
        ))

        const options = {
            filterType: 'dropdown',
            download: true,
            downloadOptions:{
                filename:'ListaUsuarios.csv', 
                separator:';'
            },
            textLabels: {
                body: {
                    noMatch: "Sem usuários cadastrados",
                },
                pagination: {
                    next: "Próxima página",
                    previous: "Página anterior",
                    rowsPerPage: "Linhas por página:",
                    displayRows: "de",
                },
                toolbar: {
                    search: "Buscar",
                    downloadCsv: "Download CSV",
                    filterTable: "Filtros",
                },
                filter: {
                    all: "Todos",
                    title: "FILTROS",
                    reset: "LIMPAR FILTRO",
                },
                selectedRows: {
                    text: "usuário(s) selecionado(s)",
                    delete: "Delete Row",
                    deleteAria: "Deleted Selected Rows"
                },
            },
            customToolbar: () => {
                return (
                    <CustomToolbar />
                );
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
            ),
            responsive: 'stacked',
            expandableRows: true,
            renderExpandableRow: (rowData, rowMeta) => {
                return (
                    <TableRow>
                        <TableCell colSpan={rowData.length}>
                            <table className="expandableRow">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>Nome CT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{(rowData[7])}</td>
                                        <td>{(rowData[2])}</td>
                                        <td>{(rowData[8])}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </TableCell>
                    </TableRow>
                );
            },
            print: false,
            viewColumns: false,
            sort: false,
            selectableRows: false,
            rowsPerPage: 100,
            rowsPerPageOptions: [100, 150, 200],
            page: this.state.page
        };

        return (
            <Main {...headerProps}>
                <MuiThemeProvider theme={theme}>

                    {(this.state.dataReady) ? (
                        <MUIDataTable
                            title={""}
                            data={ListaArray}
                            columns={columns}
                            options={options}
                        />
                    ) : (
                            <Loader />
                        )
                    }
                </MuiThemeProvider>

                <Modal open={this.state.openModal} onClose={this.onCloseModal} >
                    <div>
                        <br />
                        <p className='mensagem_modal'>Você está prestes a {(this.state.ativar) ? ('Inativar') : ('Ativar')} o acesso do usuário {this.state.usuario}, deseja continuar?</p>
                        <div style={{ textAlign: 'center' }}>
                            <Button className='mr-4 btn--bgcolor' variant="contained" type='submit' size="small" color='primary' onClick={() => {
                                this.AtivaOuInativaUsuario(this.state.ativar, this.state.idUser)
                            }}>Sim</Button>
                            <Button className='btn2--bgcolor' variant="contained" type='submit' size="small" color='default' onClick={() => { this.onCloseModal() }}>Não</Button>
                        </div>
                    </div>
                </Modal>
            </Main>
        )
    }
}

export default Usuarios;
