import Button from '@material-ui/core/Button'
import Modal from 'react-responsive-modal'
import React, { Component } from 'react'

export default class ModalAviso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalAvisoOpen: props.openAvisoModal
        }


        this.onCloseAvisoModal = this.onCloseAvisoModal.bind(this)

    }

    onAvisoModal() {
        this.setState({ modalAvisoOpen: true })
    }

    onCloseAvisoModal() {
        this.setState({ modalAvisoOpen: false })
        this.props.parentCallback(false);
    }


    render() {
        return (
            <Modal open={this.state.modalAvisoOpen} onClose={this.onCloseAvisoModal}>
                <div className="content">
                    <div className="col-sm-12 pt-4">
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>{this.props.text}</span>
                    </div>
                    <div className="row col-sm-12 pt-4">
                        <div className="col-sm-6">
                            <Button variant="contained" size="small" color="primary" style={{ color: '#ffffff', marginLeft: 15, width: '100%' }} type="submmit" onClick={this.onCloseAvisoModal} >
                                <span style={{ fontSize: 12, marginRight: 0, color: '#ffffff' }}></span>{this.props.button}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

}