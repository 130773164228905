import React, { Component } from 'react'
import Main from '../template/Main'
import CadastrarDadosPessoais from './CadastrarDadosPessoais'

let headerProps = {
    icon: 'user-plus',
    title: 'Cadastrar usuários',
    subtitle: 'Cadastrar usuários de todos os perfis'
}

let headerEditProps = {
    icon: 'user',
    title: 'Editar usuário',
    subtitle: 'Editar usuário cadastrado'
}

export default class Cadastrar extends Component {
    constructor(props) {
        super(props)

        this.handleRedirect = this.handleRedirect.bind(this)
        this.renderForm = this.renderForm.bind(this)
        this.retornaId = this.retornaId.bind(this)
    }

    renderForm() {
        return <CadastrarDadosPessoais handleRedirect={this.handleRedirect} retornaId={this.retornaId} />
    }

    handleRedirect(){
        this.props.history.replace('/home/usuarios') 
    }

    retornaId(){
        let url = window.location.href
        let id = url.split('cadastrar/')
        let idUser = id[1]

        return idUser
    }

    render() {   
        if(this.retornaId() === undefined) {
            return (
                <Main {...headerProps}>
                    {this.renderForm()}
                </Main>
            )
        }else {
            return (
                <Main {...headerEditProps}>
                    {this.renderForm()}
                </Main>
            )
        } 
        
    }
}