import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class LoginForm extends Component {
    render() {
        return (
            <form onSubmit={this.props.handleFormSubmit}>
                <div className="camposform__login">
                    <input type="text" className="form-control" id="user" maxLength="80" name="username" autoComplete="username" placeholder="Usuário" required onChange={this.props.handleChange} />
                    <i className="fas fa-user"></i>
                </div>
                <div className="camposform__senha">
                    <input type="password" className="form-control" id="senha" maxLength="10" autoComplete="current-password" name="password" placeholder="Senha" required onChange={this.props.handleChange} />
                    <i className="fas fa-key"></i>
                </div>
                <div className="camposform__esqueci">
                    <Link to="/esqueci" className="small">Esqueci minha senha</Link>
                </div>
                <button type="submit" className="btn btn-primary">ENTRAR</button>
            </form>
        )
    }
}
export default LoginForm