import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../components/home/Home'
import Cadastrar from '../components/cadastrar/Cadastrar'
import Usuarios from '../components/usuarios/Usuarios'
import Indice from '../components/indice/Indice'

export default props =>
    <Switch>
        <Route exact path='/home' component={Home} /> 
        <Route path='/home/cadastrar' component={Cadastrar} />
        <Route path='/home/usuarios' component={Usuarios} />
        <Route path='/home/indice' component={Indice} />
        <Redirect from ='*' to='/home' />
    </Switch>