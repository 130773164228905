import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "react-responsive-modal";
import Groups from "./Groups";

import "./Clients.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";

export default class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalGroupOpen: false,
      idClientSelected: "",
      todosGroupsMarcados: false,
      itemGroupSelecionado: false,
      allClientsSelected: false,
    };

    this.onOpenModalGroup = this.onOpenModalGroup.bind(this);
    this.renderClients = this.renderClients.bind(this);
    this.setStatusClient = this.setStatusClient.bind(this);
    this.onCloseClientModal = this.onCloseClientModal.bind(this);
    this.onOpenClientModal = this.onOpenClientModal.bind(this);
    this.onCloseModalGroup = this.onCloseModalGroup.bind(this);
    this.retornaTotalAtivoGroup = this.retornaTotalAtivoGroup.bind(this);
    this.marcaTodosGroupsAtivo = this.marcaTodosGroupsAtivo.bind(this);
    this.verificaTodosGroupsMarcados =
      this.verificaTodosGroupsMarcados.bind(this);
    this.setItemGroupSelecionado = this.setItemGroupSelecionado.bind(this);
    this.setAllClients = this.setAllClients.bind(this);
  }

  onOpenModalGroup(indice) {
    this.onCloseClientModal();
    this.verificaTodosGroupsMarcados(indice);

    if (this.retornaTotalAtivoGroup(this.props.clients[indice].Groups) === 0) {
      this.setState({
        modalGroupOpen: true,
        idClientSelected: indice,
        itemGroupSelecionado: false,
      });
    } else {
      this.setState({
        modalGroupOpen: true,
        idClientSelected: indice,
        itemGroupSelecionado: true,
      });
    }
  }

  onCloseModalGroup() {
    this.setState({
      modalGroupOpen: false,
    });
    this.onOpenClientModal();
  }

  setStatusClient(indice, ativo, item) {
    //CONDICIONAL PARA SELECIONAR APENAS 1 CLIENT OU TODOS
    // if(this.props.itemClientSelecionado && ativo)
    //    return

    this.props.setItemClientSelecionado(ativo);
    let lstClient = [...this.props.clients];
    lstClient[indice].ativo = ativo;
    this.props.verificaTodosClientsAtivos();
    this.props.onUpdateClient(lstClient);

    if (ativo)
      for (let i = 0; i < lstClient[indice]?.groups?.length; i++) {
        lstClient[indice].groups[i].ativo = ativo;
      }

    if (this.props.activeClients.includes(item)) {
      const newValue = this.props.activeClients.filter(
        (client) => client.Id !== item.Id
      );

      this.props.setActiveClients(newValue, false);
    } else {
      this.props.setActiveClients(item, true);
    }
  }

  setAllClients() {
    this.setState({ allClientsSelected: !this?.state?.allClientsSelected });

    if (!this?.state?.allClientsSelected) {
      this.props.setActiveClients(this?.props?.clients, false);
    } else {
      this.props.setActiveClients([], false);
    }
  }

  onCloseClientModal() {
    this.props.onClientModal(false);
  }

  onOpenClientModal() {
    this.props.onClientModal(true);
  }

  retornaTotalAtivoGroup(item) {
    let totalAtivo = 0;
    for (let index = 0; index < item.length; index++) {
      if (item[index].ativo === true) totalAtivo++;
    }

    return totalAtivo;
  }

  marcaTodosGroupsAtivo() {
    let itemSelecionado = this.state.itemGroupSelecionado;
    let todosGroupsMarcados = !this.state.todosGroupsMarcados;
    let lstClient = [...this.props.clients];

    for (
      let i = 0;
      i < lstClient[this.state.idClientSelected].Groups.length;
      i++
    )
      lstClient[this.state.idClientSelected].Groups[i].ativo =
        todosGroupsMarcados;

    if (!todosGroupsMarcados) itemSelecionado = false;

    this.setState({
      todosGroupsMarcados: todosGroupsMarcados,
      itemGroupSelecionado: itemSelecionado,
    });
    this.props.onUpdateClient(lstClient);
  }

  setItemGroupSelecionado(status) {
    this.setState({ itemGroupSelecionado: status });
  }

  verificaTodosGroupsMarcados(idClientSelected) {
    let todosSeleciodados = true;
    let lstClient = [...this.props.clients];
    for (let i = 0; i < lstClient[idClientSelected].Groups.length; i++) {
      if (!lstClient[idClientSelected].Groups[i].ativo)
        todosSeleciodados = false;
    }

    this.setState({ todosGroupsMarcados: todosSeleciodados });
  }

  renderClients() {
    return this?.props?.clients?.map((item, i) => {
      const isActiveClient = this?.props?.activeClients.some(
        (client) => client?.Id === item?.Id
      );

      return isActiveClient ? (
        <div key={item.Id} className="clients-vinculado clients-selecionado">
          <h5>{item.Nome}</h5>
          {this.retornaTotalAtivoGroup(item.Groups) === item.Groups.length ? (
            <span className="clients-vinculado__span">
              Todos os clients groups selecionados
            </span>
          ) : this.retornaTotalAtivoGroup(item.Groups) === 0 ? (
            <span className="clients-vinculado__span">
              Nenhum Client Group selecionado
            </span>
          ) : (
            <span className="clients-vinculado__span">
              {" "}
              {this.retornaTotalAtivoGroup(item.Groups)} de {item.Groups.length}{" "}
              Clients Groups selecionados{" "}
            </span>
          )}
          {/* //CONDICIONAL PARA SELECIONAR TRAVAR EDIÇÃO DE GROUPS PARA QUANDO TODOS OS CLIENTS ESTÃO SELECIONADOS */}
          {/* {!this.props.todosClientsMarcados ? ( */}
          <div>
            <span
              className="clients-vinculado__bnt-edit"
              onClick={() => this.onOpenModalGroup(i)}
            >
              <i className="fas fa-pen"></i>
            </span>
            <span
              id={"client-ativo-" + i}
              className="clients-vinculado__bnt-close clients-vinculado__bnt-activate"
              onClick={() => this.setStatusClient(i, false, item)}
            >
              <i className="fas fa-check"></i>
            </span>
          </div>
          {/* )  : ''} */}
        </div>
      ) : (
        <div key={item.Id} className="clients-vinculado">
          <h5>{item.Nome}</h5>
          <span className="clients-vinculado__span"></span>
          {/* //CONDICIONAL PARA SELECIONAR APENAS 1 CLIENT OU TODOS */}
          {/* {this.props.itemClientSelecionado ? '' : ( */}
          <span
            className="clients-vinculado__bnt-close clients-vinculado__bnt-close"
            onClick={() => this.setStatusClient(i, true, item)}
          >
            <i className="fas"></i>
          </span>
          {/* )} */}
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <Groups
          clients={this.props.clients}
          onOpenClientModal={this.onOpenClientModal}
          onCloseModalGroup={this.onCloseModalGroup}
          modalGroupOpen={this.state.modalGroupOpen}
          idClientSelected={this.state.idClientSelected}
          onUpdateClient={this.props.onUpdateClient}
          todosGroupsMarcados={this.state.todosGroupsMarcados}
          marcaTodosGroupsAtivo={this.marcaTodosGroupsAtivo}
          verificaTodosGroupsMarcados={this.verificaTodosGroupsMarcados}
          itemGroupSelecionado={this.state.itemGroupSelecionado}
          setItemGroupSelecionado={this.setItemGroupSelecionado}
        />
        <Modal
          open={this.props.modalClientsOpen ? true : false}
          onClose={this.onCloseClientModal}
        >
          <h2>Clients selecionados</h2>
          <div className="clients-todos">
            <span
              className={`clients-vinculado__bnt-close ${
                this?.state?.allClientsSelected &&
                "clients-vinculado__bnt-activate"
              }`}
              onClick={this.setAllClients}
            >
              <i className="fas todos fa-check"></i>
            </span>
            <h6>Selecionar todos os Clients</h6>
          </div>

          <div className="corpo_modal">
            <div className="corpo_clients">
              {!this.props.modalClientsOpen ? "" : this.renderClients()}
            </div>
            <div className="btn-corpo">
              <Button
                id="back-btn"
                className="btn-confirmar"
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={this.onCloseClientModal}
              >
                Voltar
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
