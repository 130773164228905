import React, { Component } from 'react'

class LoginLogo extends Component {
    render() {
        return (
            <img className="logo-vidalink" src="https://img.vidalink.com.br/shared/logo_vdk.svg" alt="logo" /> 
        )
    }
}

export default LoginLogo