import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main/App';
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'
//import DotEnv from 'dotenv';

//componentes
import Login from './components/login/Login'
import Esqueci from './components/login/Esqueci'
import Health from './components/health/Health'

import { Route, Redirect, Switch, BrowserRouter} from 'react-router-dom'

ReactDOM.render(
    <BrowserRouter>
    <div>
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/home" component={App} />
            <Route path="/Esqueci" component={Esqueci} />
            <Route path="/health" component={Health} />
            <Redirect from ='*' to='/home' />
        </Switch>
        <Alert stack={{limit: 3}} />
    </div>
    </BrowserRouter>
, document.getElementById('root'));
